import { useTranslation } from 'react-i18next'
import { Card, CardContent, Divider, IconButton, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Edit } from '@mui/icons-material'
import React from 'react'
import styled from '@emotion/styled'
import { useApp } from 'app/providers/app.provider'

export const PresentationCard = ({ presentation }: { presentation: string | null }) => {
  const { t } = useTranslation()
  const { user } = useApp()

  return (
    <Card variant="outlined" data-cy="presentation-card">
      <CardTitle>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Typography variant="h3">{t('presentation')}</Typography>
          {user?.rights.center.isEdit && (
            <IconButton size="small" color={'primary'} data-cy="title-action">
              <Edit fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </CardTitle>
      <Divider />
      <CardContent>
        <Typography>{presentation ? presentation : t('no_saved_data')}</Typography>
      </CardContent>
    </Card>
  )
}

const CardTitle = styled(Box)({
  height: 50,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
})
