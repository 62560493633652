import { Box, Button, FormControl, Grid, Paper, TextField, Typography } from '@mui/material'
import { Link } from 'app/components/link.component'
import React, { useCallback, useEffect, useState } from 'react'
import {
  formatAccountingStatus,
  formatCurrency,
  formatDate,
  formatInvoiceStatus,
} from 'app/utils/format'
import { FormItem, InvoiceInformation } from 'api/models'
import { Translation, useTranslation } from 'react-i18next'
import { SelectLanguages } from 'modules/invoices/components/languages_select.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { DatePicker } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import { BASE_CLIENT_URL } from 'api/constants'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import BaseModal from 'app/components/modals/base.modal'
import { CreditModalComponent } from 'modules/invoices/components/credit_modal.component'
import { useParams } from 'react-router-dom'

interface IProps {
  data: InvoiceInformation | undefined
  isLoading: boolean
  dataFilters: FormItem | undefined
  refresh: () => void
}

export const InformationsComponent = ({ data, isLoading, dataFilters, refresh }: IProps) => {
  const { t } = useTranslation()

  const {
    patchRegeneratePdfInvoice,
    patchAccountInvoice,
    patchUnAccountInvoice,
    patchBlockInvoice,
    patchUnBlockInvoice,
    patchLitigationInvoice,
    postUnCollectibleInvoice,
    postManualReminderInvoice,
    patchLanguageInvoice,
    patchDueDateInvoice,
  } = useFetcher()
  const { handleMutation } = useFeedback()
  const [openLitigation, setOpenLitigation] = useState(false)
  const [litigationReference, setLitigationReference] = useState('')
  const [languageId, setLanguageId] = useState<any>()
  const [invoice, setInvoice] = useState<any>()
  const [item, setItem] = useState<any>()
  const [dueDate, setDueDate] = useState<Dayjs | undefined>()
  const [openCredit, setOpenCredit] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    if (data !== undefined) {
      setItem(data)
      setInvoice(data.invoice)
      setDueDate(dayjs(data.invoice.dueDate))
      setLanguageId(data.invoice.languageId)
    }
  }, [data])

  const handleLanguage = useCallback(
    async (e: any) => {
      const languageId = Number(e.target.value)
      setLanguageId(languageId)
      await handleMutation({
        mutation: patchLanguageInvoice,
        data: {
          id: Number(id),
          data: { language: languageId },
        },
        toastSuccess: t('language_edited', { type: 'la facture' }),
        onEnd: () => refresh(),
      })
    },
    [id]
  )

  const handleDueDate = useCallback(
    async (dayjs: any) => {
      const date = dayjs.format('YYYY-MM-DD')
      await handleMutation({
        mutation: patchDueDateInvoice,
        data: {
          id: Number(id),
          data: {
            dueDate: date,
          },
        },
        onEnd: () => refresh(),
      })
    },
    [id]
  )

  const handleRegeneratePdf = useCallback(async () => {
    await handleMutation({
      mutation: patchRegeneratePdfInvoice,
      data: Number(id),
      toastSuccess: t('invoice_regenerated'),
      onEnd: () => refresh(),
    })
  }, [id])

  const handleAccount = useCallback(async () => {
    await handleMutation({
      mutation: patchAccountInvoice,
      data: Number(id),
      toastSuccess: t('document_accounted'),
      onEnd: () => refresh(),
    })
  }, [id])

  const handleUnAccount = useCallback(async () => {
    await handleMutation({
      mutation: patchUnAccountInvoice,
      data: Number(id),
      toastSuccess: t('document_unaccounted'),
      onEnd: () => refresh(),
    })
  }, [id])

  const handleBlock = useCallback(async () => {
    await handleMutation({
      mutation: patchBlockInvoice,
      data: Number(id),
      toastSuccess: t('invoice_blocked'),
      onEnd: () => refresh(),
    })
  }, [id])

  const handleUnBlock = useCallback(async () => {
    await handleMutation({
      mutation: patchUnBlockInvoice,
      data: Number(id),
      toastSuccess: t('invoice_unblocked'),
      onEnd: () => refresh(),
    })
  }, [id])

  const preHandleLitigation = useCallback(() => setOpenLitigation(true), [])

  const handleLitigation = useCallback(
    async (e: any) => {
      e.preventDefault()
      setOpenLitigation(false)

      await handleMutation({
        mutation: patchLitigationInvoice,
        data: { id: Number(id), data: { reference: litigationReference } },
        toastSuccess: t('litigation_created'),
        onEnd: () => refresh(),
      })
    },
    [id, litigationReference]
  )

  const handleUnCollectible = useCallback(async () => {
    await handleMutation({
      mutation: postUnCollectibleInvoice,
      data: Number(id),
      toastSuccess: t('invoice_uncollectible', { reference: invoice.reference }),
      onEnd: () => refresh(),
    })
  }, [id, invoice])

  const handleReminder = useCallback(async () => {
    await handleMutation({
      mutation: postManualReminderInvoice,
      data: Number(id),
      toastSuccess: t('invoice_reminder'),
      onEnd: () => refresh(),
    })
  }, [id])

  const preHandleCredit = useCallback(async () => {
    setOpenCredit(true)
  }, [])

  if (isLoading) return <CardSkeleton />

  return (
    <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
      <BaseModal
        open={openLitigation}
        setOpen={setOpenLitigation}
        title={'litigation'}
        handleUpdate={handleLitigation}
      >
        <TextField
          id="outlined-basic"
          label={t('litigation_reference')}
          variant="outlined"
          value={litigationReference}
          onChange={(e) => setLitigationReference(e.target.value)}
        />
        <Typography textAlign={'center'} style={{ opacity: 0.7, marginTop: 2 }}>
          {t('optional_reference')}
        </Typography>
      </BaseModal>
      {invoice !== undefined && (
        <CreditModalComponent
          item={item}
          invoice={invoice}
          refresh={refresh}
          openCredit={openCredit}
          setOpenCredit={setOpenCredit}
        />
      )}
      <Typography variant="h3" marginBottom="1rem" color="primary">
        <Translation>{(t) => t('informations')}</Translation>
      </Typography>
      {invoice !== undefined && (
        <Grid container spacing={2}>
          <Grid item container spacing={9}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                <Translation>{(t) => t('client')}</Translation>
              </Typography>
              <Typography variant="body2">
                <Link to={`/enterprises/${invoice.clientId}`}>{invoice.clientName}</Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ width: '100%', height: '100%' }} size={'small'}>
                <DatePicker
                  label={<Translation>{(t) => t('due_date')}</Translation>}
                  value={dueDate}
                  minDate={dayjs(invoice.minDueDate)}
                  onChange={handleDueDate}
                  slotProps={{
                    textField: { size: 'small' },
                  }}
                  disabled={!item.isInvoiceSheetEdit}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                <Translation>{(t) => t('due_amount')}</Translation>
              </Typography>
              <Typography variant="body2">{formatCurrency(invoice.dueAmount)}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                <Translation>{(t) => t('state')}</Translation>
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {formatInvoiceStatus(invoice.state, invoice.stateLabel)}
                <Typography variant="body2" style={{ marginLeft: '5px' }}>
                  {invoice.stateLabel}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                <Translation>{(t) => t('accounting_state')}</Translation>
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {formatAccountingStatus(
                  String(Number(invoice.isAccounted)),
                  invoice.accountedLabel
                )}
                <Typography variant="body2" style={{ marginLeft: '5px' }}>
                  {invoice.accountedLabel}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {dataFilters && (
                <SelectLanguages
                  filter={dataFilters}
                  data={languageId}
                  onChange={handleLanguage}
                  isEdit={item.isInvoiceEdit}
                />
              )}
            </Grid>
            {invoice.paidAt && (
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  <Translation>{(t) => t('paid_at')}</Translation>
                </Typography>
                <Typography variant="body2">{formatDate(invoice.paidAt)}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ '& button': { m: 1 } }}>
                <div>
                  {item.isCreditAdd && (
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={preHandleCredit}
                    >
                      <Translation>{(t) => t('credit')}</Translation>
                    </Button>
                  )}
                  {invoice.paymentLink && item.isInvoiceUnPaid && (
                    <Link to={`${BASE_CLIENT_URL}/${invoice.paymentLink}`} target={'_blank'}>
                      <Button size="small" variant="contained" color="info">
                        <Translation>{(t) => t('payment_page')}</Translation>
                      </Button>
                    </Link>
                  )}
                  {item.isEditable && item.isInvoiceEdit && (
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={handleRegeneratePdf}
                    >
                      <Translation>{(t) => t('invoice_regenerate')}</Translation>
                    </Button>
                  )}
                  {item.isInvoiceEdit && !invoice.isAccounted && (
                    <Button size="small" variant="contained" color="info" onClick={handleAccount}>
                      <Translation>{(t) => t('count')}</Translation>
                    </Button>
                  )}
                  {item.isInvoiceEdit && invoice.isAccounted && (
                    <Button
                      size="small"
                      variant="contained"
                      color="warning"
                      onClick={handleUnAccount}
                    >
                      <Translation>{(t) => t('derecognized')}</Translation>
                    </Button>
                  )}
                  {item.isInvoiceEdit && item.isInvoiceStateCollectible && item.isInvoiceUnPaid && (
                    <Button size="small" variant="contained" color="warning" onClick={handleBlock}>
                      <Translation>{(t) => t('block')}</Translation>
                    </Button>
                  )}
                  {item.isInvoiceEdit &&
                    item.isInvoiceStateCollectible &&
                    item.isInvoiceStateBlocked && (
                      <Button size="small" variant="contained" color="info" onClick={handleUnBlock}>
                        <Translation>{(t) => t('unblock')}</Translation>
                      </Button>
                    )}
                  {item.isLitigationAdd && (
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={preHandleLitigation}
                    >
                      <Translation>{(t) => t('litigation')}</Translation>
                    </Button>
                  )}
                  {item.isInvoiceEdit && item.isInvoiceEditUncollectible && (
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={handleUnCollectible}
                    >
                      <Translation>{(t) => t('uncollectible')}</Translation>
                    </Button>
                  )}
                  {item.isInvoiceUnPaid && (
                    <Button size="small" variant="contained" color="info" onClick={handleReminder}>
                      <Translation>{(t) => t('send_reminder')}</Translation>
                    </Button>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  )
}
