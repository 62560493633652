import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import { FormItemValue, ParkingService, FormItems } from 'api/models'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import InputLabel from '@mui/material/InputLabel'

interface IProps {
  parkingService: ParkingService
  typologies: FormItemValue[]
  options: FormItems
  methods: UseFormReturn<any>
}

export default function ParkingMainInformationsComponent({
  methods,
  parkingService,
  typologies,
  options,
}: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('main_informations')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={1}
      />
      <Divider sx={{ marginBottom: 6 }} />
      <Grid container spacing={5} marginBottom={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            {typologies && typologies.length > 0 && (
              <FormControl fullWidth={true}>
                <InputLabel id="typology-label">{t('typology')}</InputLabel>
                <Select
                  fullWidth
                  size={'small'}
                  label={t('typology')}
                  {...methods.register('typology')}
                  value={methods.getValues().typology ?? ''}
                  data-cy={'typology'}
                >
                  {typologies.map(
                    (typology) =>
                      Number(typology.id) !== 0 && (
                        <MenuItem key={typology.id} value={Number(typology.id)}>
                          {typology.label}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            {options.centers && options.centers.values.length > 0 ? (
              <FormControl fullWidth={true}>
                <InputLabel id="center-label">{t('center')}</InputLabel>
                <Select
                  fullWidth
                  size={'small'}
                  labelId={'center-label'}
                  label={t('center')}
                  {...methods.register('center')}
                  value={methods.getValues().center ?? ''}
                  data-cy={'center'}
                  required={true}
                >
                  {options.centers.values.map(
                    (center) =>
                      Number(center.id) !== 0 && (
                        <MenuItem key={center.id} value={Number(center.id)}>
                          {center.label}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            ) : (
              <TextField
                disabled
                fullWidth
                size={'small'}
                variant="outlined"
                defaultValue={parkingService.centerName}
                InputProps={{
                  readOnly: true,
                }}
                data-cy={'center-name'}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              type="number"
              label={t('floor')}
              defaultValue={parkingService.floor}
              {...methods.register('floor')}
              data-cy={'parking-floor'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              type="number"
              label={t('capacity')}
              defaultValue={parkingService.maxCapacity}
              {...methods.register('maxCapacity')}
              data-cy={'parking-max-capacity'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              label={t('building')}
              defaultValue={parkingService.building}
              {...methods.register('building')}
              data-cy={'parking-building'}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
