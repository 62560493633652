import { Grid, Typography } from '@mui/material'
import { List } from 'app/components/lists/list'
import { Euro } from '@mui/icons-material'
import {
  formatCenterServiceLabel,
  formatCurrency,
  formatServiceOnlineStatus,
} from 'app/utils/format'
import { ListPagination } from 'app/components/lists/list-pagination'
import React from 'react'
import { ConsumptionItem } from 'api/models'
import { useList } from 'app/providers/list.provider'
import { useTranslation } from 'react-i18next'

export const ConsumptionsListComponent = function ({
  consumptions,
  refreshList,
  listIsLoading,
  centerId,
}: {
  listIsLoading: boolean
  refreshList: (reset: boolean) => Promise<void>
  consumptions: ConsumptionItem[]
  centerId?: number
}) {
  const { t } = useTranslation()
  const { total, handleFilterByArray, handleSortByArray, orderBy, isLast } = useList()
  return (
    <Grid>
      <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
        <Typography variant="body2" gutterBottom>
          {consumptions.length} / {total}
        </Typography>
      </Grid>
      <List
        items={consumptions}
        columns={[
          {
            label: t('status'),
            slug: 'isOnline',
            valueFormatter: formatServiceOnlineStatus,
          },
          {
            label: t('label'),
            slug: 'label',
            text: 'isCenterPrice',
            valueFormatter: formatCenterServiceLabel,
            link: {
              base: centerId ? `/centers/${centerId}/consumptions` : '/consumptions',
              slug: 'id',
            },
          },
          { label: t('type'), slug: 'type' },
          {
            label: Euro,
            tooltip: t('price'),
            slug: 'price',
            valueFormatter: formatCurrency,
          },
        ]}
        handleReset={() => handleFilterByArray(refreshList, true)}
        sort={orderBy}
        handleSort={(property) => handleSortByArray(refreshList, property)}
        isLoading={listIsLoading}
        sx={{
          th: {
            textWrapMode: 'nowrap',
          },
        }}
      />
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom marginTop={2}>
          {consumptions.length} / {total}
        </Typography>
      </Grid>
      {!isLast && !listIsLoading && (
        <ListPagination handleRedirect={() => handleFilterByArray(refreshList, false)} />
      )}
    </Grid>
  )
}
