import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'
import { EMAIL_REGEX } from 'app/constants/regex'

const staffItemSchema = z.object({
  id: z.number({ coerce: true }),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  phone: z.string().nullable(),
  center: z.string(),
  roleReference: z.string(),
  role: z.string(),
  isActive: z.number({ coerce: true }),
  createdAt: z.string(),
  job: z.string(),
})

export const staffsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(staffItemSchema),
})

const staffDetailsSchema = z.object({
  firstname: z.string(),
  lastname: z.string(),
  phone: z.string(),
  mainCenter: z.number(),
  staffCenters: z.array(
    z.object({
      center: z.object({
        id: z.number(),
      }),
      isMain: z.boolean(),
    })
  ),
  language: z
    .object({
      id: z.number(),
    })
    .nullable(),
  staffAccount: z
    .object({
      role: z.object({
        id: z.number(),
      }),
      email: z.string(),
      isActive: z.boolean(),
    })
    .nullable(),
  job: z.string(),
  centerCluster: z
    .object({
      id: z.number(),
    })
    .nullable(),
  phoneSystemsStaff: z.array(
    z.object({
      phoneSystem: z.object({
        id: z.number(),
      }),
      reference: z.string().optional(),
    })
  ),
})

export const createUpdateStaffSchema = z
  .object({
    id: z.number().optional(),
    firstname: z.string(),
    lastname: z.string(),
    phone: z.string().optional(),
    staffCenters: z.array(
      z.object({
        center: z.object({
          id: z.number(),
        }),
        isMain: z.boolean(),
      })
    ),
    language: z.number({ coerce: true }),
    staffAccount: z.object({
      role: z.number({ coerce: true }),
      email: z.string().regex(EMAIL_REGEX),
      clearPassword: z
        .union([z.string().length(0), z.string().min(1)])
        .optional()
        .transform((e) => (e === '' ? undefined : e)),
      isActive: z.boolean().optional(),
    }),
    job: z.string().optional().nullable(),
    centerCluster: z.number({ coerce: true }).optional().nullable(),
    phoneSystemsStaff: z.array(
      z.object({
        phoneSystem: z.number(),
        reference: z.string().optional(),
      })
    ),
  })
  .transform((data) => ({
    ...data,
    phoneSystemsStaff: data.phoneSystemsStaff.filter((data) => data.reference),
    centerCluster: data.centerCluster && data.centerCluster > 0 ? data.centerCluster : null,
  }))

const staffAttributionItemSchema = z.object({
  id: z.coerce.number(),
  firstname: z.string(),
  lastname: z.string(),
  role: z.string(),
  isAttributable: z.number().transform((e) => e === 1),
})
export const staffsListAttributionSchema = itemListSchema(staffAttributionItemSchema)

export const staffDefaultSchema = z.object({
  staff: z.number(),
})

export type CreateUpdateStaffForm = z.infer<typeof createUpdateStaffSchema>
export type StaffItem = z.infer<typeof staffItemSchema>
export type StaffsList = z.infer<typeof staffsListSchema>
export type StaffAttributionItem = z.infer<typeof staffAttributionItemSchema>
export type StaffsListAttribution = z.infer<typeof staffsListAttributionSchema>
export type StaffDetails = z.infer<typeof staffDetailsSchema>
export type StaffDefault = z.infer<typeof staffDefaultSchema>
