import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { Box, Container } from '@mui/system'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'
import { ParkingsListComponent } from 'modules/services/components/parkings-list.component'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'

export const ParkingServicesView = (): React.JSX.Element => {
  const { getRight } = useApp()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setSearchParams, searchParams } = useFetcher()
  const { filtersList, initFilters, handleFilter } = useList()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['parking_services_expired', 'expired']])
  )
  const { Services } = useFetcherV2()
  const {
    items: parkingServices,
    isFetching: listIsLoading,
    refreshList,
  } = useQueryFetcherList({
    queryKey: ['parkings-services', 'list', searchParams],
    enabled: false,
    queryFn: () => Services.parkingList(searchParams.toString()),
  })

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [commonFilters])

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('parkings')}
            {getRight('catalog_service', 'isAdd') && (
              <IconButton
                title={t('parking_add')}
                aria-label={t('parking_add')}
                color="primary"
                onClick={() => navigate('/services/parking/add')}
                style={{ marginLeft: 7 }}
                data-cy={'add-button'}
              >
                <AddCircle fontSize="small" />
              </IconButton>
            )}
          </Typography>
        </Box>
        <Grid>
          <FiltersBox
            filters={filtersList}
            handleFilters={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          >
            <SearchCenterInput
              slug="center"
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </FiltersBox>
        </Grid>
        <ParkingsListComponent
          parkingServices={parkingServices}
          refreshList={refreshList}
          listIsLoading={listIsLoading}
        />
      </Grid>
    </Container>
  )
}
