import React, { useState, ChangeEvent, forwardRef, useRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { lighten, Stack, styled } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { Image, InsertDriveFile, VideoFile, Add, Close } from '@mui/icons-material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { transparentize } from 'polished'
import { dontForward } from 'app/utils/components.util'
import { useFeedback } from 'app/providers/feedback.provider'

export type IUploadZoneProps = {
  accept?: string
  formatsCaption?: string
  enforcedTypes?: string[]
  maxAmount?: number
  maxSize?: number
  onFilesChange?: (files: File[]) => void
}

export type IUploadZoneRef = {
  updateInputFiles: (files: File[]) => void
} & HTMLInputElement

export const UploadZone = forwardRef<IUploadZoneRef, IUploadZoneProps>(function UploadCard(
  props,
  ref
) {
  const {
    accept = '*',
    formatsCaption,
    maxAmount = 1,
    maxSize = 5_000_000,
    onFilesChange,
    enforcedTypes = [],
    ...extra
  } = props
  const multiple = maxAmount > 1

  const { toast } = useFeedback()
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [draggingOver, setDraggingOver] = useState(false)
  const [files, setFiles] = useState<File[]>([])

  useImperativeHandle(ref, () => ({ ...inputRef.current!, updateInputFiles }), [])

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (!fileList) return

    const newFiles = [...fileList].filter((newFile) => {
      if (!newFile) return false
      for (const file of files) {
        if (getFileKey(newFile) === getFileKey(file)) return false
      }
      return true
    })

    if (newFiles.length === 0) return
    for (const file of newFiles) {
      const enforcedTypeIndex = enforcedTypes.findIndex((t) => file.type.includes(t))
      if (enforcedTypes.length && enforcedTypeIndex === -1) {
        toast({ message: t('file_wrong_format'), variant: 'error' })
        return
      }
      if (file.size > maxSize) {
        const sizeMo = formatOctetToMo(file.size)
        const maxSizeMo = formatOctetToMo(maxSize)
        toast({
          message: t('file_too_big', { size: sizeMo, maxSize: maxSizeMo }),
          variant: 'error',
        })
        return
      }
    }

    const finalFiles = [...files, ...newFiles]

    if (multiple && finalFiles.length > maxAmount) {
      toast({
        message: t('file_too_many', { maxAmount }),
        variant: 'error',
      })
      return
    }

    onFilesChange?.(finalFiles)
    setFiles(finalFiles)
  }

  const handleRemoveFile = (file: File) => {
    const fileIndex = files.findIndex((f) => f === file)
    if (fileIndex === -1) return

    setFiles((currentFiles) => {
      const newFiles = currentFiles.toSpliced(fileIndex, 1)
      updateInputFiles(newFiles)
      onFilesChange?.(newFiles)
      return newFiles
    })
  }

  const updateInputFiles = (newFiles: File[]) => {
    if (!inputRef.current) return

    const container = new DataTransfer()
    newFiles.forEach((f) => container.items.add(f))
    inputRef.current.files = container.files
  }

  const getFileKey = (file: File) => {
    return `${file.name}-${file.size}`
  }

  return (
    <div data-cy="upload-zone">
      <DropZone $active={draggingOver} $empty={files.length === 0}>
        <DropZoneInput
          ref={inputRef}
          type="file"
          accept={accept}
          multiple={multiple}
          onDragOver={() => setDraggingOver(true)}
          onDragLeave={() => setDraggingOver(false)}
          onDrop={() => setDraggingOver(false)}
          onChange={handleFileChange}
          $enabled={files.length === 0}
          {...extra}
        />
        <Stack gap={4}>
          {files.length ? (
            <>
              {files.map((f) => (
                <FileItem key={getFileKey(f)} file={f} onRemove={handleRemoveFile} />
              ))}
              {files.length < maxAmount && (
                <AddButton onClick={() => inputRef.current?.click()}>
                  <Add color="primary" />
                </AddButton>
              )}
            </>
          ) : (
            <EmptyUpload onClick={() => inputRef.current?.click()} />
          )}
        </Stack>
      </DropZone>
      <CaptionContainer>
        <Typography variant="caption" color="grey.800">
          {formatsCaption && `${t('file_format')}: ${formatsCaption}`}
        </Typography>
        <Typography variant="caption" color="grey.800">
          {t('max_size')}: {formatOctetToMo(maxSize)}
        </Typography>
      </CaptionContainer>
    </div>
  )
})

type IFileItemProp = {
  file: File
  onRemove: (file: File) => void
}
const FileItem: React.FC<IFileItemProp> = (props) => {
  const { file, onRemove } = props
  const { name, size, type } = file
  const [hovered, setHovered] = useState(false)

  return (
    <div
      style={{ display: 'flex', gap: 5 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <FileIconWrapper>
        {(() => {
          if (type.includes('image')) return <Image color="primary" />
          if (type.includes('video')) return <VideoFile color="primary" />
          return <InsertDriveFile color="primary" />
        })()}
      </FileIconWrapper>
      <div>
        <Typography variant="h4">{name}</Typography>
        <Typography variant="caption">{formatOctetToMo(size)}</Typography>
      </div>
      <RemoveButton
        $display={hovered}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
        onClick={() => onRemove(file)}>
        <Close color="action" fontSize={'small'} data-cy="remove-file" />
      </RemoveButton>
    </div>
  )
}

type IEmptyUploadProp = {
  onClick?: () => void
}
const EmptyUpload: React.FC<IEmptyUploadProp> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Stack alignItems="center" gap={3}>
      <FileUploadIcon />
      <div>
        <Typography component="span">{t('drag_and_drop_file') + ' ' + t('or')}</Typography>
        <ChooseFileButton size="small" onClick={onClick}>
          {t('choose_file')}
        </ChooseFileButton>
      </div>
    </Stack>
  )
}

const CaptionContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const DropZone = styled(
  'div',
  dontForward(['$active', '$empty'])
)<{ $active: boolean; $empty: boolean }>(({ $active, $empty, theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: 150,
  padding: theme.spacing(5),
  borderWidth: 1,
  borderColor: $active && $empty ? theme.palette.primary.main : theme.palette.grey[300],
  backgroundColor: $empty
    ? transparentize($active ? 0.8 : 0.95, theme.palette.primary.main)
    : 'transparent',
  borderRadius: 4,
  borderStyle: 'dashed',
  transition: 'all ease-in-out 0.2s',
}))

const DropZoneInput = styled(
  'input',
  dontForward(['$enabled'])
)<{ $enabled: boolean }>(({ $enabled }) => ({
  display: $enabled ? 'block' : 'none',
  position: 'absolute',
  inset: 0,
  opacity: 0,
  width: '100%',
  height: '100%',
}))

const FileUploadIcon = styled(UploadFileIcon)(({ theme }) => ({
  fontSize: 50,
  color: lighten(theme.palette.primary.main, 0.3),
}))

const FileIconWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: 999,
  aspectRatio: '1/1',
  width: 38,
  display: 'grid',
  placeItems: 'center',
}))

const AddButton = styled('button')({
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  width: 38,
  background: 'none',
  border: 'none',
})

const RemoveButton = styled(
  'button',
  dontForward(['$display'])
)<{ $display: boolean }>(({ $display }) => ({
  opacity: $display ? 1 : 0,
  alignSelf: 'flex-start',
  transition: 'opacity 0.1s',
  cursor: 'pointer',
  padding: 2,
  marginLeft: 'auto',
  display: 'flex',
  background: 'none',
  border: 'none',
}))

const ChooseFileButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  paddingBlock: 0,
  '&:before': {
    content: '""',
    display: 'block',
    height: '1px',
    left: 5,
    right: 5,
    background: theme.palette.primary.main,
    position: 'absolute',
    bottom: 2,
  },
}))

const formatOctetToMo = (octet: number) => {
  return `${Math.round(octet / 10_000) / 100}Mo`
}
