import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Box, Grid, Tab, Tabs, Tooltip, useTheme } from '@mui/material'
import { Container, styled } from '@mui/system'
import { useFetcher } from 'app/providers/fetcher.provider'
import { CenterDetails } from 'api/models'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useList } from 'app/providers/list.provider'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { MainServicesListComponent } from 'modules/services/components/main-services-list.component'
import { ParkingsListComponent } from 'modules/services/components/parkings-list.component'
import { OptionsListComponent } from 'modules/services/components/options-list.component'
import { ConsumptionsListComponent } from 'modules/services/components/consumptions-list.component'
import { TabPanel } from 'app/components/tab-panel.component'
import { InfoRounded } from '@mui/icons-material'

interface CenterCatalogProps {
  center: CenterDetails
}

const SubTabs = styled(Tabs)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300],
  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    zIndex: 0,
    borderRadius: theme.spacing(2),
  },
  '.MuiTab-root': {
    color: theme.palette.text,
    backgroundColor: 'transparent',
    minWidth: 120,
    zIndex: 1,
  },
  '.Mui-selected': {
    color: theme.palette.primary.contrastText,
  },
}))

enum CatalogueTabsEnum {
  MAIN,
  PARKINGS,
  OPTIONS,
  CONSUMPTIONS,
}

const tabsItems = [
  {
    name: 'main_services',
    value: CatalogueTabsEnum.MAIN,
  },
  {
    name: 'parkings',
    value: CatalogueTabsEnum.PARKINGS,
  },
  {
    name: 'options',
    value: CatalogueTabsEnum.OPTIONS,
  },
  {
    name: 'consumptions',
    value: CatalogueTabsEnum.CONSUMPTIONS,
  },
]

const MainServiceTab = function ({ center }: CenterCatalogProps) {
  const { Services } = useFetcherV2()
  const { initSort } = useList()
  const { searchParams } = useFetcher()
  const {
    items: services,
    refreshList,
    isLoading: listIsLoading,
  } = useQueryFetcherList({
    queryKey: ['center_catalog_main_services', center.id, searchParams],
    enabled: false,
    queryFn: () => {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('center', center.id.toString())
      return Services.mainList(newSearchParams.toString())
    },
  })

  useEffect(() => {
    initSort([]).then(() => refreshList(true))
  }, [])

  return (
    <MainServicesListComponent
      centerId={center.id}
      mainServices={services ?? []}
      listIsLoading={listIsLoading}
      refreshList={refreshList}
    />
  )
}

const OptionsTab = function ({ center }: CenterCatalogProps) {
  const { Options } = useFetcherV2()
  const { searchParams } = useFetcher()
  const {
    items: services,
    refreshList,
    isLoading: listIsLoading,
  } = useQueryFetcherList({
    queryKey: ['center_catalog_options', center.id, searchParams],
    enabled: false,
    queryFn: () => {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('center', center.id.toString())
      return Options.list(newSearchParams.toString())
    },
  })

  useEffect(() => {
    refreshList(true)
  }, [])

  return (
    <OptionsListComponent
      centerId={center.id}
      options={services ?? []}
      listIsLoading={listIsLoading}
      refreshList={refreshList}
    />
  )
}

const ConsumptionsTab = function ({ center }: CenterCatalogProps) {
  const { Consumptions } = useFetcherV2()
  const { searchParams } = useFetcher()
  const {
    items: services,
    refreshList,
    isLoading: listIsLoading,
  } = useQueryFetcherList({
    queryKey: ['center_catalog_consumptions', center.id, searchParams],
    enabled: false,
    queryFn: () => {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('center', center.id.toString())
      return Consumptions.list(newSearchParams.toString())
    },
  })

  useEffect(() => {
    refreshList(true)
  }, [])

  return (
    <ConsumptionsListComponent
      centerId={center.id}
      consumptions={services ?? []}
      listIsLoading={listIsLoading}
      refreshList={refreshList}
    />
  )
}

const ParkingsTab = function ({ center }: CenterCatalogProps) {
  const { Services } = useFetcherV2()
  const { searchParams } = useFetcher()
  const {
    items: services,
    refreshList,
    isLoading: listIsLoading,
  } = useQueryFetcherList({
    queryKey: ['center_catalog_parkings', center.id, searchParams],
    enabled: false,
    queryFn: () => {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('center', center.id.toString())
      return Services.parkingList(newSearchParams.toString())
    },
  })

  useEffect(() => {
    refreshList(true)
  }, [])

  return (
    <ParkingsListComponent
      centerId={center.id}
      parkingServices={services ?? []}
      refreshList={refreshList}
      listIsLoading={listIsLoading}
    />
  )
}

export const CenterCatalog = ({ center }: CenterCatalogProps) => {
  const theme = useTheme()
  const [tab, setTab] = useState(CatalogueTabsEnum.MAIN)
  const { t } = useTranslation()
  const { searchParams, setSearchParams } = useFetcher()

  return (
    <Grid
      container
      spacing={6}
      p={theme.spacing(6)}
      sx={{ marginBottom: theme.spacing(8) }}
      alignItems={'stretch'}
    >
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: 4 }}>
        <SubTabs
          sx={{ mb: 2 }}
          value={tab}
          onChange={(_, value) => {
            searchParams.forEach((value, key) => searchParams.delete(key))
            setSearchParams(searchParams)
            setTab(value)
          }}
          variant={'scrollable'}
        >
          {tabsItems.map((tab) => (
            <Tab data-cy={tab.name} key={tab.value} label={t(tab.name)} value={tab.value} />
          ))}
        </SubTabs>
        {(tab === CatalogueTabsEnum.CONSUMPTIONS || tab === CatalogueTabsEnum.OPTIONS) && (
          <Tooltip title={t('tooltip_catalog_center_rows')} placement={'right'}>
            <InfoRounded />
          </Tooltip>
        )}
      </Box>
      <Container>
        <Grid>
          <TabPanel value={tab} index={CatalogueTabsEnum.MAIN}>
            <MainServiceTab center={center} />
          </TabPanel>
          <TabPanel value={tab} index={CatalogueTabsEnum.OPTIONS}>
            <OptionsTab center={center} />
          </TabPanel>
          <TabPanel value={tab} index={CatalogueTabsEnum.CONSUMPTIONS}>
            <ConsumptionsTab center={center} />
          </TabPanel>
          <TabPanel value={tab} index={CatalogueTabsEnum.PARKINGS}>
            <ParkingsTab center={center} />
          </TabPanel>
        </Grid>
      </Container>
    </Grid>
  )
}
