import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, FormControlLabel, Grid, InputAdornment, Switch, TextField } from '@mui/material'
import { Euro, Lock } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MainService } from 'api/models'
import { UseFormReturn } from 'react-hook-form'

interface IProps {
  methods: UseFormReturn<any>
  mainService: MainService
  calculatedPrice: string | undefined
}

export default function PriceCalculatorComponent({
  methods,
  mainService,
  calculatedPrice,
}: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('price')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={1}
      />
      <Divider sx={{ marginBottom: 6 }} />
      <Grid container spacing={5} marginBottom={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              required={true}
              label={t('monthly_price_locked')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock fontSize={'small'} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01,
                },
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.price}
              {...methods.register('price', {
                setValueAs: (value) => (value && value > 0 ? parseFloat(value) : undefined),
              })}
              data-cy={'monthly-price-service-main-service'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              label={t('daily_price')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01,
                },
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.dailyPrice}
              {...methods.register('dailyPrice')}
              data-cy={'daily-price-service-main-service'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              label={t('half_day_price')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01,
                },
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.halfDayPrice}
              {...methods.register('halfDayPrice')}
              data-cy={'halfDayPrice-price-service-main-service'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              label={t('hourly_price')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01,
                },
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.hourlyPrice}
              {...methods.register('hourlyPrice')}
              data-cy={'hourlyPrice-price-service-main-service'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              disabled
              fullWidth
              size={'small'}
              label={t('calculated_monthly_price')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01,
                },
                readOnly: true,
              }}
              type="number"
              variant="outlined"
              value={calculatedPrice ?? ''}
              data-cy={'calculated-monthly-price-service-main-service'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <FormControlLabel
              disabled={
                mainService.halfDayPrice === null &&
                mainService.hourlyPrice === null &&
                mainService.dailyPrice === null
              }
              label={t('global_update_price_message')}
              control={<Switch />}
              {...methods.register('isMassivePrice')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
