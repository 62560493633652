import React, { useEffect, useMemo, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { formatSurface } from 'app/utils/format'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { MainServicesListComponent } from 'modules/services/components/main-services-list.component'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

export const MainServicesView = (): React.JSX.Element => {
  const { getRight } = useApp()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { handleFilter } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['main_services_types', 'type'],
      ['main_services_typologies', 'typology'],
      ['main_services_expired', 'expired'],
      ['staff_centers', 'center'],
      ['is_image_associated', 'imageAssociated'],
    ])
  )
  const { Services } = useFetcherV2()

  const {
    data: summary,
    isFetching: summaryIsLoading,
    refetch,
  } = useQueryFetcher({
    queryKey: ['main-services', 'summary', searchParams],
    enabled: false,
    queryFn: () => {
      return Services.getMainSum(searchParams.toString())
    },
  })

  const {
    items: mainServices,
    isFetching: listIsLoading,
    refreshList,
  } = useQueryFetcherList({
    queryKey: ['main-services', 'list', searchParams],
    queryFn: async () => {
      await refetch()
      return Services.mainList(searchParams.toString())
    },
  })

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    if (!summary) return
    newSummaryItems.set('sumCapacity', summary.sumCapacity)
    newSummaryItems.set('total_surface', formatSurface(summary.sumSurface))
    setSummaryItems(newSummaryItems)
  }, [summary])

  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<null | HTMLElement>(null)
  const isAddMenuOpen = useMemo(() => Boolean(addMenuAnchorEl), [addMenuAnchorEl])
  const openAddMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAddMenuAnchorEl(event.currentTarget)
  }
  const closeAddMenu = () => {
    setAddMenuAnchorEl(null)
  }

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('main_services')}
          {getRight('catalog_service', 'isAdd') && (
            <>
              <IconButton
                title={t('add_service')}
                aria-label={t('add_service')}
                color="primary"
                onClick={openAddMenu}
                style={{ marginLeft: 7 }}
                data-cy="add-service-button"
              >
                <AddCircle fontSize="small" />
              </IconButton>
              <Menu
                anchorEl={addMenuAnchorEl}
                open={isAddMenuOpen}
                onClose={closeAddMenu}
                data-cy="add-service-menu"
              >
                <MenuItem onClick={() => navigate('/services/add')}>
                  {t('add_main_service')}
                </MenuItem>
                <MenuItem onClick={() => navigate('/services/add-many')}>
                  {t('add_many_main_services')}
                </MenuItem>
              </Menu>
            </>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          onChange={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersContainer>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />
        <MainServicesListComponent
          refreshList={refreshList}
          listIsLoading={listIsLoading}
          mainServices={mainServices}
        />
      </Grid>
    </Container>
  )
}
