import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { SignInView } from 'modules/auth/views/signin.view'
import { CircularProgress } from '@mui/material'
import { DetailsLayout } from 'app/pages/details.layout'
import { ListLayout } from 'app/pages/list.layout'
import { AppLayout } from 'app/pages/layout'
import { EnterprisesView } from 'modules/enterprises/views/enterprises.view'
import { EnterpriseView } from 'modules/enterprises/views/enterprise.view'
import { ContractsView } from 'modules/contracts/views/contracts.view'
import { ContractView } from 'modules/contracts/views/contract.view'
import { InvoicesView } from 'modules/invoices/views/invoices.view'
import { ContractOptionsView } from 'modules/contracts/views/options.view'
import { CreditsView } from 'modules/credits/views/credits'
import { OccupancyView } from 'modules/services/views/occupancy.view'
import { LitigationsView } from 'modules/contracts/views/litigations.view'
import { OpportunitiesView } from 'modules/opportunities/views/opportunities.view'
import { IndividualsView } from 'modules/clients/views/individuals.view'
import { CheckoutsView } from 'modules/contracts/views/checkouts'
import { RightsView } from 'modules/staff/views/rights.view'
import { MandatesView } from 'modules/contracts/views/mandates'
import { MainServicesView } from 'modules/services/views/main_services'
import { PrescribersView } from 'modules/prescribers/views/prescribers'
import { PaymentsView } from 'modules/payments/views/payments.view'
import { AccountingDocumentsView } from 'modules/accounting/views/documents.view'
import { QuotationsView } from 'modules/quotations/views/quotations.view'
import { ContractsConsumptionsView } from 'modules/contracts/views/consumptions.views'
import { ConsumptionsView } from 'modules/consumptions/views/consumptions.view'
import { DuplicateEnteprisesView } from 'modules/enterprises/views/duplicate_enterprises.view'
import { IndexingView } from 'modules/contracts/views/indexing'
import { EndorsementsView } from 'modules/contracts/views/endorsements.view'
import { ClientRequestsView } from 'modules/clientServices/views/client_requests.view'
import { AccountingClientsView } from 'modules/accounting/views/clients.view'
import { CustomerReservationsView } from 'modules/clientServices/views/customer_reservations'
import DashboardView from 'modules/dashboard/views/dashboard.view'
import { AccountingPaymentsView } from 'modules/accounting/views/payments.views'
import { EnterpriseEditView } from 'modules/enterprises/views/enterprise-edit.view'
import { OpportunityAddView } from 'modules/opportunities/views/opportunity-add.view'
import { ConsumptionAddView } from 'modules/consumptions/views/consumption-add.view'
import { IndividualView } from 'modules/clients/views/individual.view'
import { InvoiceView } from 'modules/invoices/views/invoice.view'
import { IndividualEditView } from 'modules/clients/views/individual-edit.view'
import { CreditView } from 'modules/credits/views/credit'
import PricerDrawerComponent from 'modules/pricers/components/pricer-drawer.component'
import PricerView from 'modules/pricers/views/pricer.view'
import { QuotationView } from 'modules/quotations/views/quotation.view'
import { IndividualAddView } from 'modules/clients/views/individual-add.view'
import { OpportunityView } from 'modules/opportunities/views/opportunity.view'
import { OpportunityEditView } from 'modules/opportunities/views/opportunity-edit.view'
import { CustomerReservationView } from 'modules/clientServices/views/customer_reservation.view'
import { EntepriseAddView } from 'modules/enterprises/views/enteprise-add.view'
import { PricerProvider } from 'app/providers/pricer.provider'
import { ClientRequestView } from 'modules/clientServices/views/client_request.view'
import { PlanningDesktopsView } from 'modules/plannings/views/desktops.view'
import { FloorPlansView } from 'modules/floorplans/views/floor_plans.view'
import { FloorPlansManagementView } from 'modules/floorplans/views/floor_plans_management.view'
import { FloorPlanDetailsView } from 'modules/floorplans/views/floor_plan_details.view'
import { CustomerReservationsAddView } from 'modules/clientServices/views/customer_reservation.add.view'
import { ConsumptionContractView } from 'modules/contractConsumptions/views/contract-consumptions.view'
import { DocumentView } from 'modules/enterprises/views/document.view'
import { ActivitiesList } from 'modules/activities/components/activities-list'
import { ParkingServicesView } from 'modules/services/views/parking_services.view'
import { MessageView } from 'modules/monitoring/views/message.view'
import { ContractEditView } from 'modules/contracts/views/contract-edit.view'
import { MandateView } from 'modules/contracts/views/mandate.view'
import { EventsView } from 'modules/events/views/events.view'
import { StatsRateOccupancyView } from 'modules/stats/views/stats-rate-occupation.view'
import { LitigationView } from 'modules/contracts/views/litigation.view'
import { StatsInventoriesView } from 'modules/stats/views/stats-inventories.view'
import { AcquisitionCostView } from 'modules/sources/views/acquisition-cost'
import { StatsSurfaceView } from 'modules/stats/views/stats-surface.view'
import { WifiLogsView } from 'modules/wifi/views/wifi-logs'
import { OptionsAddView } from 'modules/options/views/options-add.view'
import { LoginView } from 'modules/auth/views/login.view'
import { ForgottenPasswordView } from 'modules/auth/views/forgotten_password.view'
import { NewPasswordView } from 'modules/auth/views/new_password.view'
import { StatsClientRequestView } from 'modules/stats/views/client-request.view'
import { WifiClientsLogs } from 'modules/wifi/views/wifi-clients-logs'
import { MainServiceView } from 'modules/services/views/main_service'
import { SourcesView } from 'modules/sources/views/sources'
import { SourceAddView } from 'modules/sources/views/source-add.view'
import { DiscountGridsView } from 'modules/discountGrids/views/discount-grids.view'
import { DiscountGridView } from 'modules/discountGrids/views/discount-grid.view'
import { StatsPerformanceView } from 'modules/stats/views/stats-performance.view'
import { StaffsView } from 'modules/staff/views/staffs.view'
import { PrescriberView } from 'modules/prescribers/views/prescriber.view'
import { CenterPricesView } from 'modules/centerprices/views/centerprices.view'
import { MainServiceEditView } from 'modules/services/views/main_service.edit.view'
import { CentersView } from 'modules/centers/views/centers.view'
import { DiscountGridsCenterView } from 'modules/discountGridsCenter/views/discount-grids-center.view'
import { ParkingServiceView } from 'modules/services/views/parking_service.view'
import { OptionsView } from 'modules/services/views/options.view'
import { OptionView } from 'modules/services/views/option.views'
import { OptionEditView } from 'modules/services/views/option.edit'
import { StaffAddEditView } from 'modules/staff/views/staff-add-edit.view'
import { ParkingServiceEditView } from 'modules/services/views/parking_service.edit.view'
import { InternalDocumentsView } from 'modules/termsOfSales/views/internalDocuments.view'
import { InternalDocumentView } from 'modules/termsOfSales/views/internalDocument.view'
import { MainServiceAddView } from 'modules/services/views/main_service.add.view'
import { ParkingServiceAddView } from 'modules/services/views/parking_service.add.view'
import { ConsumptionServiceView } from 'modules/consumptions/views/consumption.view'
import { TertiariesView } from 'modules/tertiary/views/tertiaries.view'
import { CompaniesView } from 'modules/companies/views/companies.view'
import { CompanyView } from 'modules/companies/views/company.view'
import { CompanyBankAccountAddView } from 'modules/companies/views/company-bank-account.add.view'
import { CompanyBankAccountEditView } from 'modules/companies/views/company-bank-account.edit.view'
import { ClustersView } from 'modules/centers/views/clusters.view'
import { StatsAcquisitionsView } from 'modules/stats/views/stats-acquisitions.view'
import { StatsCenterAcquisitionsView } from 'modules/stats/views/stats-center-acquisitions.view'
import { StatsStaffAcquisitionsView } from 'modules/stats/views/stats_staff_acquisitions.view'
import { StatsCAView } from 'modules/stats/views/stats-ca.view'
import { StatsTurnoverView } from 'modules/stats/views/stats-turnover.view'
import { TypologiesView } from 'modules/typologies/views/typologies.view'
import { ConsumptionServiceEditView } from 'modules/consumptions/views/consumption.edit.view'
import { AuthentificationRadiusViews } from 'modules/monitoring/views/authentification_radius.views'
import { CommitmentsView } from 'modules/commitments/views/commitments.view'
import { EmailsView } from 'modules/emails/views/emails.view'
import { EmailView } from 'modules/emails/views/email.view'
import { EmailEditView } from 'modules/emails/views/email.edit.view'
import { DiscountsView } from 'modules/discounts/views/discounts.view'
import { DiscountView } from 'modules/discounts/views/discount.view'
import { CenterDiscountsView } from 'modules/discountCenter/views/center-discounts.view'
import { EventsCentersView } from 'modules/events/views/events-centers.view'
import { HappeningAddView } from 'modules/events/views/happening-add.view'
import { HappeningDetailsView } from 'modules/events/views/happening-details.view'
import { HappeningListPartnersView } from 'modules/events/views/happening-list-partners.view'
import { HappeningEditView } from 'modules/events/views/happening-edit.view'
import { PartnerAddView } from 'modules/events/views/happening-partners-add.view'
import { CenterView } from 'modules/centers/views/center.view'
import { CenterInformationEditView } from 'modules/centers/views/center-information.edit.view'
import { CenterLocationEditView } from 'modules/centers/views/center-location.edit.view'
import { CenterConfigurationEditView } from 'modules/centers/views/center-configuration.edit.view'
import { StatsHealthCenterView } from 'modules/stats/views/health-center.view'
import { CryptView } from 'modules/crypt/views/crypt.view'
import { PaymentView } from 'modules/payments/views/payment.view'
import { AccountingVariousOperationsView } from 'modules/accounting/views/various_operations.view'
import { MatchingTransfersExceptionView } from 'modules/accounting/views/transfers_exceptions.view'
import { BusinessPlanView } from 'modules/centers/views/business-plan.view'
import { AttributionsView } from 'modules/opportunityAttributions/views/attributions.view'
import { AttributionLogsView } from 'modules/opportunityAttributions/views/attribution-logs.view'
import { UsersPresencesView } from 'modules/presences/views/users-presences.view'
import { NotificationsView } from 'modules/notifications/views/notifications.view'
import { RightEditView } from 'modules/staff/views/right.edit.view'
import { CenterAddView } from 'modules/centers/views/center.add.view'
import { ServiceConsumptionAdd } from 'modules/contracts/views/consumption-add.view'
import { AccountingDashboardView } from 'modules/accounting/views/dashboard-accounting.view'
import { PerformanceChannelView } from 'modules/stats/views/performance-channel.view'
import { PresenceLogsView } from 'modules/presences/views/presence-logs.view'
import { PresenceConsumptionView } from 'modules/presences/views/presence-consumption.view'
import { ServiceOptionsAdd } from 'modules/services/views/option.add.view'
import { CompanyAddView } from 'modules/companies/views/company-add.view'
import { ServicesTypesView } from 'modules/services/views/services_types.view'
import { DiscountGridAddView } from 'modules/discountGrids/views/discount-grid.add.view'
import { StatsCaVsBpView } from 'modules/stats/views/stats-ca-vs-bp.view'
import { MainServiceAddManyView } from 'modules/services/views/main_service.add_many.view'
import { StatsParkingsView } from 'modules/stats/views/stats-parkings.view'
import { StatsDomiciliationView } from 'modules/stats/views/stats-domiciliation-contracts.view'
import { StatsRateOccupancyGlobalView } from 'modules/stats/views/stats-rate-occupation-global.view'
import { StatsNomadicContractsView } from 'modules/stats/views/stats-nomadic-contracts.view'
import { PhoneCallView } from 'modules/phoneCall/view/phone-call.view'
import { FloorPlansEditView } from 'modules/floorplans/views/floor_plans_edit.view'
import { CompaniesDomiciledView } from 'modules/domiciliation/views/companies-domiciled.view'
import { UsersActifView } from 'modules/contacts/views/contacts.view'

type Route = {
  path: string
  element: JSX.Element
  children?: ChildRoute[]
}

type ChildRoute = {
  path: string
  element: JSX.Element
  permissions?: string[]
}

export default function Router() {
  const routes: Route[] = [
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: '/',
          element: (
            <ListLayout>
              <DashboardView />
            </ListLayout>
          ),
        },
        {
          path: '/emails',
          element: (
            <ListLayout>
              <EmailsView />
            </ListLayout>
          ),
        },
        {
          path: '/emails/:id',
          element: (
            <ListLayout>
              <EmailView />
            </ListLayout>
          ),
        },
        {
          path: '/emails/:id/edit',
          element: (
            <ListLayout>
              <EmailEditView />
            </ListLayout>
          ),
        },
        {
          path: '/cryptography',
          element: (
            <ListLayout>
              <CryptView />
            </ListLayout>
          ),
        },
        {
          path: '/notifications',
          element: (
            <ListLayout>
              <NotificationsView />
            </ListLayout>
          ),
        },
        {
          path: '/centerprices',
          element: (
            <ListLayout>
              <CenterPricesView />
            </ListLayout>
          ),
        },
        {
          path: '/tertiaries',
          element: (
            <ListLayout>
              <TertiariesView />
            </ListLayout>
          ),
        },
        {
          path: '/companies',
          element: (
            <ListLayout>
              <CompaniesView />
            </ListLayout>
          ),
        },
        {
          path: '/companies/add',
          element: (
            <ListLayout>
              <CompanyAddView />
            </ListLayout>
          ),
        },
        {
          path: '/companies/:id',
          element: (
            <ListLayout>
              <CompanyView />
            </ListLayout>
          ),
        },
        {
          path: '/companies/:id/add_bank_account',
          element: (
            <ListLayout>
              <CompanyBankAccountAddView />
            </ListLayout>
          ),
        },
        {
          path: '/companies/:companyId/bank_account/:bankAccountId/edit',
          element: (
            <ListLayout>
              <CompanyBankAccountEditView />
            </ListLayout>
          ),
        },
        {
          path: '/enterprises',
          element: (
            <ListLayout>
              <EnterprisesView />
            </ListLayout>
          ),
        },
        {
          path: '/messages',
          element: (
            <ListLayout>
              <MessageView />
            </ListLayout>
          ),
        },
        {
          path: '/authentifications_client',
          element: (
            <ListLayout>
              <AuthentificationRadiusViews />
            </ListLayout>
          ),
        },
        {
          path: '/enterprises/add',
          element: (
            <ListLayout>
              <EntepriseAddView />
            </ListLayout>
          ),
        },
        {
          path: '/enterprises/:id',
          element: (
            <DetailsLayout asideComponent={<ActivitiesList />}>
              <EnterpriseView />
            </DetailsLayout>
          ),
        },
        {
          path: '/enterprises/:id/edit',
          element: (
            <DetailsLayout>
              <EnterpriseEditView />
            </DetailsLayout>
          ),
        },
        {
          path: '/enterprises/:id/document/:documentId',
          element: <DocumentView />,
        },
        {
          path: '/enterprises/:id/opportunities/add',
          element: (
            <DetailsLayout>
              <OpportunityAddView />
            </DetailsLayout>
          ),
        },
        {
          path: '/enterprises/:enterpriseId/individuals/add',
          element: (
            <DetailsLayout>
              <IndividualAddView />
            </DetailsLayout>
          ),
        },
        {
          path: '/discounts',
          element: (
            <ListLayout>
              <DiscountsView />
            </ListLayout>
          ),
        },
        {
          path: '/discounts/:id',
          element: (
            <ListLayout>
              <DiscountView />
            </ListLayout>
          ),
        },
        {
          path: '/discountgrids',
          element: (
            <DetailsLayout>
              <DiscountGridsView />
            </DetailsLayout>
          ),
        },
        {
          path: '/discountgrids/add',
          element: (
            <DetailsLayout>
              <DiscountGridAddView />
            </DetailsLayout>
          ),
        },
        {
          path: '/centers',
          element: (
            <ListLayout>
              <CentersView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:id',
          element: (
            <ListLayout>
              <CenterView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:id/informations/edit',
          element: (
            <ListLayout>
              <CenterInformationEditView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/add',
          element: (
            <ListLayout>
              <CenterAddView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:id/location/edit',
          element: (
            <ListLayout>
              <CenterLocationEditView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:id/configuration/edit',
          element: (
            <ListLayout>
              <CenterConfigurationEditView />
            </ListLayout>
          ),
        },
        {
          path: '/bp',
          element: (
            <ListLayout>
              <BusinessPlanView />
            </ListLayout>
          ),
        },
        {
          path: '/clusters',
          element: (
            <ListLayout>
              <ClustersView />
            </ListLayout>
          ),
        },
        {
          path: '/discountgrids-center',
          element: (
            <DetailsLayout>
              <DiscountGridsCenterView />
            </DetailsLayout>
          ),
        },
        {
          path: '/discountgrids/:id',
          element: (
            <DetailsLayout>
              <DiscountGridView />
            </DetailsLayout>
          ),
        },
        {
          path: '/enterprises/:enterpriseId/consumptions/add',
          element: (
            <ListLayout>
              <ConsumptionAddView />
            </ListLayout>
          ),
        },
        {
          path: '/consumptions/add',
          element: (
            <ListLayout>
              <ConsumptionAddView />
            </ListLayout>
          ),
        },
        {
          path: '/contracts',
          element: (
            <ListLayout>
              <ContractsView />
            </ListLayout>
          ),
        },
        {
          path: '/contracts/:id',
          element: (
            <DetailsLayout asideComponent={<ActivitiesList />}>
              <ContractView />
            </DetailsLayout>
          ),
        },
        {
          path: '/contracts/:id/edit',
          element: (
            <ListLayout>
              <ContractEditView />
            </ListLayout>
          ),
        },
        {
          path: '/contracts/:id/options/add',
          element: (
            <ListLayout>
              <OptionsAddView />
            </ListLayout>
          ),
        },
        {
          path: '/users-actif',
          element: (
            <ListLayout>
              <UsersActifView />
            </ListLayout>
          ),
        },
        {
          path: '/consumption-contracts/:id',
          element: (
            <ListLayout>
              <ConsumptionContractView />
            </ListLayout>
          ),
        },
        {
          path: '/invoices',
          element: (
            <ListLayout>
              <InvoicesView />
            </ListLayout>
          ),
        },
        {
          path: '/options',
          element: (
            <ListLayout>
              <OptionsView />
            </ListLayout>
          ),
        },
        {
          path: '/options/:id',
          element: (
            <ListLayout>
              <OptionView />
            </ListLayout>
          ),
        },
        {
          path: '/options/:id/edit',
          element: (
            <ListLayout>
              <OptionEditView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:centerId/options/:id',
          element: (
            <ListLayout>
              <OptionView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:centerId/options/:id/edit',
          element: (
            <ListLayout>
              <OptionEditView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:centerId/consumptions/:id/edit',
          element: (
            <ListLayout>
              <ConsumptionServiceEditView />
            </ListLayout>
          ),
        },
        {
          path: '/credits',
          element: (
            <ListLayout>
              <CreditsView />
            </ListLayout>
          ),
        },
        {
          path: '/credits/:id',
          element: (
            <ListLayout>
              <CreditView />
            </ListLayout>
          ),
        },
        {
          path: '/floorplans',
          element: (
            <ListLayout>
              <FloorPlansView />
            </ListLayout>
          ),
        },
        {
          path: '/floor-plans-management',
          element: (
            <ListLayout>
              <FloorPlansManagementView />
            </ListLayout>
          ),
        },
        {
          path: '/floor-plans-management/:id',
          element: (
            <ListLayout>
              <FloorPlanDetailsView />
            </ListLayout>
          ),
          permissions: ['floor_plan.isShow'],
        },
        {
          path: '/floor-plans-management/:id/edit',
          element: (
            <ListLayout>
              <FloorPlansEditView />
            </ListLayout>
          ),
          permissions: ['floor_plan.isEdit'],
        },
        {
          path: '/services/occupancy',
          element: (
            <ListLayout>
              <OccupancyView />
            </ListLayout>
          ),
        },
        {
          path: '/services/options/add',
          element: (
            <ListLayout>
              <ServiceOptionsAdd />
            </ListLayout>
          ),
        },
        {
          path: '/rights',
          element: (
            <ListLayout>
              <RightsView />
            </ListLayout>
          ),
        },
        {
          path: '/rights/:id',
          element: (
            <ListLayout>
              <RightEditView />
            </ListLayout>
          ),
        },
        {
          path: '/litigations',
          element: (
            <ListLayout>
              <LitigationsView />
            </ListLayout>
          ),
        },
        {
          path: '/litigations/:id',
          element: (
            <ListLayout>
              <LitigationView />
            </ListLayout>
          ),
        },
        {
          path: '/events',
          element: (
            <ListLayout>
              <EventsView />
            </ListLayout>
          ),
        },
        {
          path: '/happenings',
          element: (
            <ListLayout>
              <EventsCentersView />
            </ListLayout>
          ),
        },
        {
          path: '/partner',
          element: (
            <ListLayout>
              <HappeningListPartnersView />
            </ListLayout>
          ),
        },
        {
          path: '/partner/add',
          element: (
            <ListLayout>
              <PartnerAddView />
            </ListLayout>
          ),
        },
        {
          path: '/happenings/:id',
          element: (
            <ListLayout>
              <HappeningDetailsView />
            </ListLayout>
          ),
        },
        {
          path: '/happenings/add',
          element: (
            <ListLayout>
              <HappeningAddView />
            </ListLayout>
          ),
        },
        {
          path: '/happenings/:id/edit',
          element: (
            <ListLayout>
              <HappeningEditView />
            </ListLayout>
          ),
        },
        {
          path: '/opportunities',
          element: (
            <ListLayout>
              <OpportunitiesView />
            </ListLayout>
          ),
        },
        {
          path: '/opportunities/:id',
          element: (
            <DetailsLayout asideComponent={<ActivitiesList />}>
              <OpportunityView />
            </DetailsLayout>
          ),
        },
        {
          path: '/opportunities/:id/edit',
          element: (
            <DetailsLayout>
              <OpportunityEditView />
            </DetailsLayout>
          ),
        },
        {
          path: '/individuals',
          element: (
            <ListLayout>
              <IndividualsView />
            </ListLayout>
          ),
        },
        {
          path: '/individuals/:id',
          element: (
            <DetailsLayout asideComponent={<ActivitiesList />}>
              <IndividualView />
            </DetailsLayout>
          ),
        },
        {
          path: '/individuals/:id/edit',
          element: (
            <DetailsLayout>
              <IndividualEditView />
            </DetailsLayout>
          ),
        },
        {
          path: '/individuals/:individualId/consumptions/add',
          element: (
            <ListLayout>
              <ConsumptionAddView />
            </ListLayout>
          ),
        },
        {
          path: '/checkouts',
          element: (
            <ListLayout>
              <CheckoutsView />
            </ListLayout>
          ),
        },
        {
          path: '/contracts-options-subscribed',
          element: (
            <ListLayout>
              <ContractOptionsView />
            </ListLayout>
          ),
        },
        {
          path: '/mandates',
          element: (
            <ListLayout>
              <MandatesView />
            </ListLayout>
          ),
        },
        {
          path: '/mandates/:id',
          element: (
            <ListLayout>
              <MandateView />
            </ListLayout>
          ),
        },
        {
          path: '/services/main',
          element: (
            <ListLayout>
              <MainServicesView />
            </ListLayout>
          ),
        },
        {
          path: '/services/add',
          element: (
            <ListLayout>
              <MainServiceAddView />
            </ListLayout>
          ),
        },
        {
          path: '/services/add-many',
          element: (
            <ListLayout>
              <MainServiceAddManyView />
            </ListLayout>
          ),
        },
        {
          path: '/services/parking/add',
          element: (
            <ListLayout>
              <ParkingServiceAddView />
            </ListLayout>
          ),
        },
        {
          path: '/services-types',
          element: (
            <ListLayout>
              <ServicesTypesView />
            </ListLayout>
          ),
        },
        {
          path: '/center-discounts',
          element: (
            <ListLayout>
              <CenterDiscountsView />
            </ListLayout>
          ),
        },
        {
          path: '/services/main/:id',
          element: (
            <ListLayout>
              <MainServiceView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:centerId/services/main/:id',
          element: (
            <ListLayout>
              <MainServiceView />
            </ListLayout>
          ),
        },
        {
          path: '/services/main/:id/edit',
          element: (
            <ListLayout>
              <MainServiceEditView />
            </ListLayout>
          ),
        },
        {
          path: '/services/parking',
          element: (
            <ListLayout>
              <ParkingServicesView />
            </ListLayout>
          ),
        },
        {
          path: '/services/parking/:id',
          element: (
            <ListLayout>
              <ParkingServiceView />
            </ListLayout>
          ),
        },
        {
          path: 'centers/:centerId/services/parking/:id',
          element: (
            <ListLayout>
              <ParkingServiceView />
            </ListLayout>
          ),
        },
        {
          path: 'centers/:centerId/services/parking/:id',
          element: (
            <DetailsLayout>
              <ParkingServiceView />
            </DetailsLayout>
          ),
        },
        {
          path: '/services/parking/:id/edit',
          element: (
            <ListLayout>
              <ParkingServiceEditView />
            </ListLayout>
          ),
        },
        {
          path: '/commitments',
          element: (
            <DetailsLayout>
              <CommitmentsView />
            </DetailsLayout>
          ),
        },
        {
          path: '/prescribers',
          element: (
            <ListLayout>
              <PrescribersView />
            </ListLayout>
          ),
        },
        {
          path: '/enterprises/:enterpriseId/prescribers/:prescriberId',
          element: (
            <ListLayout>
              <PrescriberView />
            </ListLayout>
          ),
        },
        {
          path: '/payments',
          element: (
            <ListLayout>
              <PaymentsView />
            </ListLayout>
          ),
        },
        {
          path: '/payments/:id',
          element: (
            <ListLayout>
              <PaymentView />
            </ListLayout>
          ),
        },
        {
          path: '/matchingtransfersexception',
          element: (
            <ListLayout>
              <MatchingTransfersExceptionView />
            </ListLayout>
          ),
        },
        {
          path: '/accounting_documents',
          element: (
            <ListLayout>
              <AccountingDocumentsView />
            </ListLayout>
          ),
        },
        {
          path: '/accountingvariousoperations',
          element: (
            <ListLayout>
              <AccountingVariousOperationsView />
            </ListLayout>
          ),
        },
        {
          path: '/quotations',
          element: (
            <ListLayout>
              <QuotationsView />
            </ListLayout>
          ),
        },
        {
          path: '/quotations/:id',
          element: (
            <DetailsLayout>
              <QuotationView />
            </DetailsLayout>
          ),
        },
        {
          path: '/contract-consumptions',
          element: (
            <ListLayout>
              <ContractsConsumptionsView />
            </ListLayout>
          ),
        },
        {
          path: '/consumptions',
          element: (
            <ListLayout>
              <ConsumptionsView />
            </ListLayout>
          ),
        },
        {
          path: '/services/consumptions/add',
          element: (
            <ListLayout>
              <ServiceConsumptionAdd />
            </ListLayout>
          ),
        },
        {
          path: '/consumptions/:id',
          element: (
            <ListLayout>
              <ConsumptionServiceView />
            </ListLayout>
          ),
        },
        {
          path: '/consumptions/:id/edit',
          element: (
            <ListLayout>
              <ConsumptionServiceEditView />
            </ListLayout>
          ),
        },
        {
          path: '/centers/:centerId/consumptions/:id',
          element: (
            <ListLayout>
              <ConsumptionServiceView />
            </ListLayout>
          ),
        },
        {
          path: '/enterprises-duplicate',
          element: (
            <ListLayout>
              <DuplicateEnteprisesView />
            </ListLayout>
          ),
        },
        {
          path: '/indexing',
          element: (
            <ListLayout>
              <IndexingView />
            </ListLayout>
          ),
        },
        {
          path: '/endorsements',
          element: (
            <ListLayout>
              <EndorsementsView />
            </ListLayout>
          ),
        },
        {
          path: '/clientrequests',
          element: (
            <ListLayout>
              <ClientRequestsView />
            </ListLayout>
          ),
        },
        {
          path: '/clientrequests/:id',
          element: (
            <DetailsLayout>
              <ClientRequestView />
            </DetailsLayout>
          ),
        },
        {
          path: '/accountingclients',
          element: (
            <ListLayout>
              <AccountingClientsView />
            </ListLayout>
          ),
        },
        {
          path: '/customer_reservations',
          element: (
            <ListLayout>
              <CustomerReservationsView />
            </ListLayout>
          ),
        },
        {
          path: '/customer_reservations/:id',
          element: (
            <DetailsLayout>
              <CustomerReservationView />
            </DetailsLayout>
          ),
        },
        {
          path: '/customer_reservations/add',
          element: (
            <ListLayout>
              <CustomerReservationsAddView />
            </ListLayout>
          ),
        },
        {
          path: '/accountingpayments',
          element: (
            <ListLayout>
              <AccountingPaymentsView />
            </ListLayout>
          ),
        },
        {
          path: '/invoices/:id',
          element: (
            <ListLayout>
              <InvoiceView />
            </ListLayout>
          ),
        },
        {
          path: '/staffs',
          element: (
            <ListLayout>
              <StaffsView />
            </ListLayout>
          ),
        },
        {
          path: '/staffs/add',
          element: (
            <ListLayout>
              <StaffAddEditView />
            </ListLayout>
          ),
        },
        {
          path: '/staffs/:id/edit',
          element: (
            <ListLayout>
              <StaffAddEditView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-acquisition',
          element: (
            <ListLayout>
              <StatsAcquisitionsView />
            </ListLayout>
          ),
        },
        {
          path: '/center-production',
          element: (
            <ListLayout>
              <StatsCenterAcquisitionsView />
            </ListLayout>
          ),
        },
        {
          path: '/staff-production',
          element: (
            <ListLayout>
              <StatsStaffAcquisitionsView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-ca',
          element: (
            <ListLayout>
              <StatsCAView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-turnover',
          element: (
            <ListLayout>
              <StatsTurnoverView />
            </ListLayout>
          ),
        },
        {
          path: '/statsinventories',
          element: (
            <ListLayout>
              <StatsInventoriesView />
            </ListLayout>
          ),
        },
        {
          path: '/statsrateoccupancy',
          element: (
            <ListLayout>
              <StatsRateOccupancyView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-rate-occupancy-global',
          element: (
            <ListLayout>
              <StatsRateOccupancyGlobalView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-surface',
          element: (
            <ListLayout>
              <StatsSurfaceView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-parkings',
          element: (
            <ListLayout>
              <StatsParkingsView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-nomadic-contracts',
          element: (
            <ListLayout>
              <StatsNomadicContractsView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-domiciliation-contracts',
          element: (
            <ListLayout>
              <StatsDomiciliationView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-client-requests',
          element: (
            <ListLayout>
              <StatsClientRequestView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-performance',
          element: (
            <ListLayout>
              <StatsPerformanceView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-health-center',
          element: (
            <ListLayout>
              <StatsHealthCenterView />
            </ListLayout>
          ),
        },
        {
          path: '/performance-channel',
          element: (
            <ListLayout>
              <PerformanceChannelView />
            </ListLayout>
          ),
        },
        {
          path: '/accounting_dashboard',
          element: (
            <ListLayout>
              <AccountingDashboardView />
            </ListLayout>
          ),
        },
        {
          path: '/stats-ca-vs-bp',
          element: (
            <ListLayout>
              <StatsCaVsBpView />
            </ListLayout>
          ),
        },
        {
          path: '/pricer',
          element: (
            <PricerProvider>
              <DetailsLayout asideComponent={<PricerDrawerComponent />}>
                <PricerView />
              </DetailsLayout>
            </PricerProvider>
          ),
        },
        {
          path: 'plannings/services',
          element: (
            <ListLayout>
              <PlanningDesktopsView />
            </ListLayout>
          ),
        },
        {
          path: '/sources',
          element: (
            <ListLayout>
              <SourcesView />
            </ListLayout>
          ),
        },
        {
          path: '/sources/add',
          element: (
            <ListLayout>
              <SourceAddView />
            </ListLayout>
          ),
        },
        {
          path: '/acquisition-costs',
          element: (
            <ListLayout>
              <AcquisitionCostView />
            </ListLayout>
          ),
        },
        {
          path: '/terms-of-sales',
          element: (
            <ListLayout>
              <InternalDocumentsView type={'term-of-sales'} />
            </ListLayout>
          ),
        },
        {
          path: '/rules-of-procedures',
          element: (
            <ListLayout>
              <InternalDocumentsView type={'rules-of-procedure'} />
            </ListLayout>
          ),
        },
        {
          path: '/terms-of-sales/:id',
          element: (
            <ListLayout>
              <InternalDocumentView type={'term-of-sales'} />
            </ListLayout>
          ),
        },
        {
          path: '/rules-of-procedures/:id',
          element: (
            <ListLayout>
              <InternalDocumentView type={'rules-of-procedure'} />
            </ListLayout>
          ),
        },
        {
          path: '/typologies',
          element: (
            <ListLayout>
              <TypologiesView />
            </ListLayout>
          ),
        },
        {
          path: '/presences/logs',
          element: (
            <ListLayout>
              <PresenceLogsView />
            </ListLayout>
          ),
        },
        {
          path: '/presences/users',
          element: (
            <ListLayout>
              <UsersPresencesView />
            </ListLayout>
          ),
        },
        {
          path: '/presences/consumption',
          element: (
            <ListLayout>
              <PresenceConsumptionView />
            </ListLayout>
          ),
        },
        {
          path: 'domiciliation/companies-domiciled',
          element: (
            <ListLayout>
              <CompaniesDomiciledView />
            </ListLayout>
          ),
        },
        {
          path: '/wifi/clients',
          element: (
            <ListLayout>
              <WifiClientsLogs />
            </ListLayout>
          ),
        },
        {
          path: '/wifi/logs',
          element: (
            <ListLayout>
              <WifiLogsView />
            </ListLayout>
          ),
        },
        {
          path: '/opportunity-attribution',
          element: (
            <ListLayout>
              <AttributionsView />
            </ListLayout>
          ),
        },
        {
          path: '/opportunity-attribution-logs',
          element: (
            <ListLayout>
              <AttributionLogsView />
            </ListLayout>
          ),
        },
        {
          path: '/phone-calls',
          element: (
            <ListLayout>
              <PhoneCallView />
            </ListLayout>
          ),
          permissions: [],
        },
      ],
    },
    {
      path: '/login',
      element: <LoginView />,
    },
    {
      path: '/sign-in',
      element: <SignInView />,
    },
    {
      path: '/forgotten_password',
      element: <ForgottenPasswordView />,
    },
    {
      path: '/forgotten_password/new',
      element: <NewPasswordView />,
    },
  ]

  const router = createBrowserRouter(routes)

  return <RouterProvider router={router} fallbackElement={<CircularProgress />} />
}
