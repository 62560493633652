import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { MainService } from 'api/models'
import { formatCurrency, formatDate, formatSurface, formatYesNo } from 'app/utils/format'
import { useApp } from 'app/providers/app.provider'
import InfoCard from 'app/components/card/info-card.component'
import LockIcon from '@mui/icons-material/Lock'
import { Stack } from '@mui/system'
import { AlertsInfo } from 'modules/services/components/alerts-info.component'
import { ServiceEnum } from 'modules/services/enums/service_enum'

interface IServiceDetailsProps {
  mainService?: MainService
  type: string,
  isLoading : boolean
}

export const ServiceDetails = ({ mainService, type, isLoading }: IServiceDetailsProps) => {
  const { t } = useTranslation()
  const { getLanguages } = useApp()

  const { savedLabels, info, availability, price } = useMemo(() => {
    let savedLabels = getLanguages().map((language) => ({
      label: language.label,
      value:
        mainService?.savedLabels.find((savedLabel) => savedLabel.language.id === language.id)
          ?.label ?? '',
    }))

    const info = [
      {
        label: t('type'),
        value: mainService?.serviceTypeLabel,
      },
      {
        label: t('center'),
        value: mainService?.centerName,
      },
      {
        label: t('surface'),
        value: formatSurface(String(mainService?.surface)),
      },
      {
        label: t('capacity'),
        value: mainService?.maxCapacity,
      },
      {
        label: t('typology'),
        value: mainService?.typologyLabel,
      },
      {
        label: t('floor'),
        value: mainService?.floor,
      },
      {
        label: t('description'),
        value: mainService?.description,
      },
    ]

    const availability = [
      {
        label: t('begin'),
        value: formatDate(mainService?.begin ?? ''),
      },
      {
        label: t('end'),
        value: mainService?.end ? formatDate(mainService?.end ?? '') : '',
      },
      {
        label: t('visible_client_space'),
        value: t(formatYesNo(mainService?.isOnline ?? false)),
      },
    ]

    const price =
      type === ServiceEnum.PARKING
        ? [
            {
              label: t('monthly_price'),
              value: (
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  {mainService?.isMonthlyPriceBlocked && <LockIcon fontSize={'small'} />}
                  {formatCurrency(String(mainService?.monthlyPrice))}
                </Stack>
              ),
            },
          ]
        : [
            {
              label: t('monthly_price'),
              value: (
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  {mainService?.isMonthlyPriceBlocked && <LockIcon fontSize={'small'} />}
                  {formatCurrency(String(mainService?.monthlyPrice))}
                </Stack>
              ),
            },
            {
              label: t('daily_price'),
              value: formatCurrency(mainService?.dailyPrice ? String(mainService?.dailyPrice) : ''),
            },
            {
              label: t('half_day_price'),
              value: formatCurrency(
                mainService?.halfDayPrice ? String(mainService?.halfDayPrice) : ''
              ),
            },
            {
              label: t('hourly_price'),
              value: formatCurrency(mainService?.hourlyPrice ? String(mainService?.hourlyPrice) : ''),
            },
          ]
    return { savedLabels, info, availability, price }
  }, [mainService, t])

  return (
    <>
      <InfoCard isLoading={isLoading} title={t('label')} columns={savedLabels} />
      <InfoCard isLoading={isLoading} title={t('informations')} columns={info}>
        <AlertsInfo alerts={mainService?.alerts ?? []} />
      </InfoCard>
      <InfoCard isLoading={isLoading} title={t('availability')} columns={availability} />
      <InfoCard isLoading={isLoading} title={t('price')} columns={price} />
    </>
  )
}
