import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import { MainService, FormItemValue, FormItems } from 'api/models'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import InputLabel from '@mui/material/InputLabel'

interface IProps {
  mainService: MainService
  methods: UseFormReturn<any>
  options: FormItems
}

export default function MainInformationsComponent({ methods, mainService, options }: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('main_informations')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={1}
      />
      <Divider sx={{ marginBottom: 6 }} />
      <Grid container spacing={5} marginBottom={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            {options.main_services_types && options.main_services_types.values.length > 0 ? (
              <Select
                fullWidth
                label={t('type')}
                size={'small'}
                {...methods.register('serviceType')}
                value={methods.getValues().serviceType ?? ''}
              >
                {options.main_services_types.values.map((item: FormItemValue) => (
                  <MenuItem key={item.id} value={Number(item.id)}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                disabled
                fullWidth
                size={'small'}
                variant="outlined"
                defaultValue={mainService.serviceTypeLabel}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            {options.centers && options.centers.values.length > 0 ? (
              <Select
                fullWidth
                label={t('center')}
                size={'small'}
                {...methods.register('center')}
                value={methods.getValues().center ?? ''}
                required={true}
              >
                {options.centers.values.map(
                  (center) =>
                    Number(center.id) !== 0 && (
                      <MenuItem key={center.id} value={Number(center.id)}>
                        {center.label}
                      </MenuItem>
                    )
                )}
              </Select>
            ) : (
              <TextField
                disabled
                fullWidth
                size={'small'}
                label={t('center')}
                variant="outlined"
                defaultValue={mainService.centerName}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              label={t('surface')}
              variant="outlined"
              type="number"
              required={true}
              InputProps={{
                inputProps: {
                  step: 0.01,
                },
              }}
              {...methods.register('surface')}
              data-cy={'main-service-surface'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              label={t('capacity')}
              variant="outlined"
              type="number"
              defaultValue={mainService.maxCapacity}
              {...methods.register('maxCapacity')}
              data-cy={'main-service-max-capacity'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            {options.main_services_typologies &&
              options.main_services_typologies.values.length > 0 && (
                <FormControl fullWidth={true}>
                  <InputLabel id="typology-label">{t('typology')}</InputLabel>
                  <Select
                    fullWidth
                    required={true}
                    labelId="typology-label"
                    size={'small'}
                    {...methods.register('typology')}
                    value={methods.getValues().typology ?? ''}
                    label={t('typology')}
                  >
                    <MenuItem value={''}>{''}</MenuItem>
                    {options.main_services_typologies.values.map(
                      (typology) =>
                        Number(typology.id) !== 0 && (
                          <MenuItem key={typology.id} value={Number(typology.id)}>
                            {typology.label}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              label={t('floor')}
              variant="outlined"
              type="number"
              defaultValue={mainService.floor}
              {...methods.register('floor')}
              data-cy={'main-service-floor'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              label={t('description')}
              variant="outlined"
              defaultValue={mainService.description}
              multiline
              rows={2}
              {...methods.register('description')}
              data-cy={'main-service-description'}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
