import { Grid, Typography } from '@mui/material'
import { List } from 'app/components/lists/list'
import {
  formatCenterServiceLabel,
  formatCurrency,
  formatDate,
  formatServiceOnlineStatus,
} from 'app/utils/format'
import { ListPagination } from 'app/components/lists/list-pagination'
import React, { useCallback } from 'react'
import { Option } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'

export const OptionsListComponent = function ({
  options,
  listIsLoading,
  refreshList,
  centerId,
}: {
  options: Option[]
  listIsLoading: boolean
  refreshList: (reset: boolean) => Promise<void>
  centerId?: number
}) {
  const { t } = useTranslation()
  const { total, isLast, orderBy, handleSortByArray, handleFilterByArray } = useList()

  const feesFormatter = useCallback((item: Option) => {
    if (item['fees'] > 0) {
      return item['fees']
    }
    if (item['freeFees'] !== 0) {
      return item['freeFees']
    }
    return ''
  }, [])

  const priceFormatter = useCallback((item: Option) => {
    if (item['price'] > 0) {
      return item['price']
    }
    if (item['freePrice'] !== 0) {
      return item['freePrice']
    }
    return ''
  }, [])

  return (
    <>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {options.length} / {total}
          </Typography>
        </Grid>
        <List
          items={options}
          columns={[
            {
              label: t('state'),
              slug: 'isOnline',
              valueFormatter: formatServiceOnlineStatus,
            },
            {
              label: t('label'),
              slug: 'label',
              text: 'isCenterPrice',
              valueFormatter: formatCenterServiceLabel,
              link: {
                base: centerId ? `/centers/${centerId}/options` : '/options',
                slug: 'id',
              },
            },
            { label: t('type'), slug: 'type' },
            {
              label: t('installation_costs'),
              slug: 'fees',
              valueFormatter: formatCurrency,
              condition: feesFormatter,
            },
            {
              label: t('monthly_price'),
              slug: 'price',
              valueFormatter: formatCurrency,
              condition: priceFormatter,
            },
            { label: t('begin_at'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end_at'), slug: 'end', valueFormatter: formatDate },
          ]}
          handleReset={() => handleFilterByArray(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSortByArray(() => refreshList(true), property)}
          isLoading={listIsLoading}
          sx={{
            th: {
              textWrapMode: 'nowrap',
            },
          }}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {options.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilterByArray(refreshList, false)} />
        )}
      </Grid>
    </>
  )
}
