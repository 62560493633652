import React, { useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'
import { OptionsListComponent } from 'modules/services/components/options-list.component'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'

export const OptionsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const navigate = useNavigate()
  const { handleFilterByArray } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const { Options } = useFetcherV2()
  const {
    items: options,
    refreshList,
    isFetching: listIsLoading,
  } = useQueryFetcherList({
    queryKey: ['options', 'list', searchParams],
    queryFn: () => Options.list(searchParams.toString()),
  })

  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['option_type', 'type'],
      ['option_expired', 'expired'],
    ])
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('options')}
          {getRight('catalog_option', 'isAdd') && (
            <IconButton
              title={t('add_options')}
              aria-label={t('add_options')}
              color="primary"
              onClick={() => navigate('/services/options/add')}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          onChange={() => handleFilterByArray(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </Grid>
      <OptionsListComponent
        options={options}
        refreshList={refreshList}
        listIsLoading={listIsLoading}
      />
    </Container>
  )
}
