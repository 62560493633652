import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { API, BASE_URL } from 'api/constants'
import {
  ClientLinkList,
  EventsList,
  FormVisorAccessData,
  IndividualAccessList,
  IndividualConsumptions,
  IndividualDetails,
  IndividualDevices,
  IndividualInvoices,
  IndividualList,
  IndividualPartners,
  IndividualWifi,
  UpdateClientLinkProps,
  WifiPasswordUpdate
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const individualsRouter = ({ token }: Ctx) => ({
  activate: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}/activate`,
      method: 'POST',
      token: token
    }),
  list: async (params: string) =>
    fetcher<IndividualList>({
      url: `${BASE_URL}${API.INDIVIDUALS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  updateClientLink: async (params: UpdateClientLinkProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_INDIVIDUAL_CLIENTLINK, {
        id: params.individualId,
        clientId: params.id
      })}`,
      method: 'PATCH',
      token: token,
      body: params.data
    }),
  getOne: async (id: number) =>
    fetcher<IndividualDetails>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}`,
      method: 'GET',
      token: token
    }),
  getWifi: async (id: number) =>
    fetcher<IndividualWifi>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}/wifi-informations`,
      method: 'GET',
      token: token
    }),
  getPartners: async (id: number) =>
    fetcher<IndividualPartners>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}/partners-informations`,
      method: 'GET',
      token: token
    }),

  listEvents: async (id: number) =>
    fetcher<EventsList>({
      url: `${BASE_URL}${API.INDIVIDUAL_EVENTS.replace(':id', String(id))}`,
      method: 'GET',
      token: token
    }),
  listEnterprises: async (id: number) =>
    fetcher<ClientLinkList>({
      url: `${BASE_URL}${API.INDIVIDUAL_ENTERPRISES.replace(':id', String(id))}`,
      method: 'GET',
      token: token
    }),
  listConsumptions: async (id: number) =>
    fetcher<IndividualConsumptions>({
      url: `${BASE_URL}${API.INDIVIDUAL_CONSUMPTIONS.replace(':id', String(id))}`,
      method: 'GET',
      token: token
    }),
  listInvoices: async (id: number) =>
    fetcher<IndividualInvoices>({
      url: `${BASE_URL}${API.INDIVIDUAL_INVOICES.replace(':id', String(id))}`,
      method: 'GET',
      token: token
    }),
  listDevices: async (id: number) =>
    fetcher<IndividualDevices>({
      url: `${BASE_URL}${API.INDIVIDUAL_DEVICES.replace(':id', String(id))}`,
      method: 'GET',
      token: token
    }),
  updateDevices: async (id: number, data: any) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_INDIVIDUAL_DEVICES, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  resetPassword: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}/send`,
      method: 'POST',
      token: token
    }),
  update: async (id: number, data: IndividualDetails) =>
    fetcher<void>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}`,
      method: 'PUT',
      token: token,
      body: data
    }),
  createWifiCredentials: async (id: number, data: WifiPasswordUpdate) =>
    fetcher<void>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}/createradiususer`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateWifiPassword: async (id: number, data: WifiPasswordUpdate) =>
    fetcher<void>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}/updateradiuspassword`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  updateActivCorner: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}/activcorner`,
      method: 'PATCH',
      token: token
    }),
  sendWifiPassword: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${API.INDIVIDUALS}/${id}/sendradiuspassword`,
      method: 'POST',
      token: token
    }),
  listAccess: async (id: number) =>
    fetcher<IndividualAccessList>({
      url: `${BASE_URL}${urlHelper(API.INDIVIDUAL_ACCESS, { id })}`,
      method: 'GET',
      token: token
    }),
  createAccess: async (id: number, data: FormVisorAccessData) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.INDIVIDUAL_ACCESS, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateAccess: async (id: number, data: FormVisorAccessData) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.INDIVIDUAL_ACCESS, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  getExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.GET_INDIVIDUALS_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      `users_${new Date().toLocaleDateString()}.csv`
    )
})
