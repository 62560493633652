import React from 'react'
import { useParams} from 'react-router-dom'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack} from '@mui/system'
import { OptionAndConsumptionServiceDetails } from 'modules/options/components/option-details.component'
import { Edit, KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

export const OptionView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id, centerId } = useParams()
  const { Services } = useFetcherV2()
  const { data: optionService, isFetching: listIsLoading } = useQueryFetcher({
    queryKey: ['options', 'get', id, centerId],
    queryFn: () => {
      if (!id) return
      return Services.optionGet(Number(id), centerId ? Number(centerId) : undefined)
    },
  })

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={centerId ? `/centers/${centerId}` : `/options`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{ optionService?.centerName ? `${t('option')} | ${optionService.centerName}` : t('option') }</Typography>
            <Link to={`./edit`}>
              <Edit fontSize={'small'}/>
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <OptionAndConsumptionServiceDetails
        isLoading={listIsLoading || !optionService}
        service={optionService}
        center={optionService?.centerId ?? null}
      />
    </Container>
  );
}
