import React, { useEffect, useRef, useState } from 'react'
import { Container, Grid, Typography, Button, Card, CardContent } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { UploadFile } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CenterBp } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { DialogUploadZone } from 'app/components/dialog/dialog-upload-zone.component'
import { formatDateWithFormat } from 'app/utils/format'
import { DialogRef } from 'app/components/dialog/dialog.component'

export const BusinessPlanView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getBusinessPlan, uploadBusinessPlanCSV } = useFetcher()
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dialogRef = useRef<DialogRef>(null)
  const [files, setFiles] = useState<File[]>([])
  const [centerBps, setCenterBps] = useState([] as CenterBp[])

  const navigate = useNavigate()
  const onSubmit = () => {
    if (files.length) {
      handleMutation({
        onStart: () => setIsLoading(true),
        mutation: uploadBusinessPlanCSV,
        data: files,
        confirm: {
          title: t('confirm'),
          content: t('confirm_add_business_plan'),
        },
        toastSuccess: t('success'),
        onEnd: () => setIsLoading(false),
        onSuccess: () => {
          navigate(0)
        },
      }).then()
    } else {
      return
    }
  }

  const getCenterBp = async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getBusinessPlan,
      onSuccess: (data) => {
        setCenterBps(data)
        setIsLoading(false)
      },
      onEnd: () => setIsLoading(false),
    })
  }

  useEffect(() => {
    getCenterBp().then()
  }, [])

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">{t('business_plan')}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" justifyContent="space-between" spacing={2} mt={4}>
            <div>
              <Button
                color="primary"
                variant="contained"
                disabled={!files}
                onClick={dialogRef.current?.open}
                startIcon={<UploadFile />}
                sx={{ display: 'flex', mb: 4, mt: 2 }}>
                {t('choose_file')}
              </Button>
            </div>
            {!isLoading && (
              <Card sx={{ flex: 1 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid container>
                      {centerBps.map((centerBp, index) => (
                        <Stack direction="row" spacing={1} mr={5} key={index}>
                          <Typography sx={{ fontSize: '0.2em', fontWeight: 'bold' }}>
                            {centerBp.center}
                          </Typography>
                          <Typography sx={{ fontSize: '0.2em', fontWeight: 'bold' }}>:</Typography>
                          <Typography sx={{ fontSize: '0.2em', fontWeight: 'bold' }}>
                            {formatDateWithFormat(centerBp.begin, 'YYYY/MM')}
                          </Typography>
                        </Stack>
                      ))}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Stack>
        </Grid>
      </Grid>
      <DialogUploadZone
        title={t('add_bp')}
        dialogRef={dialogRef}
        onFilesChange={setFiles}
        formatsCaption=".csv"
        accept=".csv"
        enforcedTypes={['csv']}
        actions={
          <Button
            sx={{ display: 'block', ml: 'auto', mr: 5, mb: 3 }}
            color="secondary"
            variant="contained"
            disabled={!files.length}
            onClick={onSubmit}>
            {t('send')}
          </Button>
        }
      />
    </Container>
  )
}
