import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useFetcher } from 'app/providers/fetcher.provider'

interface IProps {
  methods: UseFormReturn<any>
  disabled?: boolean
}

export default function AccountingInformationsComponent({ methods, disabled }: IProps) {
  const { t } = useTranslation()

  const serviceType = methods.watch('serviceType')
  const { useGetServiceTypeAccountingById } = useFetcher()
  const { data: selectedServiceTypeAccounting } = useGetServiceTypeAccountingById(serviceType)

  return (
    <>
      <TitleComponent
        text={t('accounting_informations')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={1}
      />
      <Divider sx={{ marginBottom: 6 }} />
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'serviceAccounting.0.accountingNumber'}
            control={methods.control}
            placeholder={serviceType ? selectedServiceTypeAccounting?.accountingNumber : ''}
            variant="outlined"
            label={t('accounting_number')}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'serviceAccounting.0.analyticPlan'}
            control={methods.control}
            placeholder={
              selectedServiceTypeAccounting?.analyticPlan || ''
            }
            variant="outlined"
            label={t('analytic_plan')}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'serviceAccounting.0.accountingName'}
            control={methods.control}
            placeholder={
              selectedServiceTypeAccounting?.accountingName || ''
            }
            variant="outlined"
            label={t('accounting_name')}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </>
  )
}
