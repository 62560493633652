import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button, Divider, Grid, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useCallback, useMemo, useRef } from 'react'
import { FormItems, QuotationInformation } from 'api/models'
import { formatDate } from 'app/utils/format'
import { AlternateEmail, Edit } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { QuotationEditDialog } from 'modules/quotations/components/quotation-edit-dialog.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useNavigate } from 'react-router-dom'
import { QuotationSolutionsList } from 'modules/quotations/components/quotation-solutions-list.component'
import InfoCard, { CardItemName } from 'app/components/card/info-card.component'

interface IQuotationDetailsProps {
  quotation: QuotationInformation
  options: FormItems
  children?: React.ReactNode
}

const ErrorText = styled(Typography)`
  display: inline;
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 600;
`

export const QuotationDetails = ({ quotation, options }: IQuotationDetailsProps) => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { cancelQuotation, sendQuotation, getQuotationDownload } = useFetcher()
  const { user } = useApp()
  const dialogRef = useRef<DialogRef>(null)
  const navigate = useNavigate()

  const cancel = useCallback(async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_cancel_quotation')
      },
      mutation: cancelQuotation,
      data: quotation.id,
      toastSuccess: t('quotation_cancel_success'),
      toastError: t('an_error_occurred'),
      onSuccess: () => navigate(0)
    })
  }, [quotation, cancelQuotation, t])

  const send = useCallback(async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_send_quotation')
      },
      mutation: sendQuotation,
      data: quotation.id,
      toastSuccess: t('quotation_send_success'),
      toastError: t('an_error_occurred'),
      onSuccess: () => navigate(0)
    })
  }, [quotation, sendQuotation, t])

  const getPdf = useCallback(async () => {
    await getQuotationDownload.mutateAsync(quotation.id)
  }, [quotation])

  const editButton = useMemo(
    () =>
      user?.rights.quotation_deadline.isEdit ? (
        <IconButton
          aria-label={t('transform_to_contract')}
          size={'small'}
          onClick={() => dialogRef.current?.open()}
        >
          <Edit color="primary" fontSize={'small'} />
        </IconButton>
      ) : null,
    [user, dialogRef, t]
  )

  return (
    <InfoCard
      title={t('informations')}
      columns={[
        {
          label: t('opportunity'),
          link: `/opportunities/${quotation.opportunityId}`,
          value: quotation.opportunityReference
        },
        {
          label: t('enterprise'),
          link: `/enterprises/${quotation.enterpriseId}`,
          value: quotation.enterpriseName
        },
        {
          label: t('due_date'),
          value: (
            <>
              {formatDate(quotation.dueDate)} {editButton}
            </>
          )
        },
        {
          label: t('contact'),
          value: (
            <Stack direction={'row'} gap={1}>
              <CardItemName variant="body2">{quotation.contactName}</CardItemName>
              <AlternateEmail sx={{ fontSize: '1rem', verticalAlign: 'middle' }} />
              {quotation.contactIsValidEmail ? (
                <ErrorText>
                  {quotation.contactEmail} ({t('invalid_blocked_email')})
                </ErrorText>
              ) : (
                <Typography>{quotation.contactEmail}</Typography>
              )}
            </Stack>
          )
        },
        {
          label: t('language'),
          value: (
            <>
              {quotation.language} {editButton}
            </>
          )
        },
        {
          label: t('center'),
          value: quotation.centerName,
          link: `/centers/${quotation.centerId}`
        }
      ]}
    >
      <Grid container columns={12} padding={'1rem'} gap={'0.5rem'}>
        <Grid item xs={6} sm={'auto'}>
          <Button
            size="small"
            variant={'outlined'}
            href={quotation.interactiveQuoteLink}
            target={'_blank'}
            fullWidth
          >
            {t('interactive_quotation')}
          </Button>
        </Grid>
        {quotation.status !== 3 && (
          <Grid item xs={6} sm={'auto'}>
            <Button size="small" variant={'outlined'} fullWidth onClick={getPdf} data-cy={'downloadDevis'}>
              {t('download_quotation')}
            </Button>
          </Grid>
        )}
        {quotation.status === 1 && quotation.contactEmail && !quotation.contactIsValidEmail && (
          <Grid item xs={6} sm={'auto'}>
            <Button size="small" data-cy={'sendQuotation'} variant={'outlined'} fullWidth onClick={send}>
              {t('send_quotation')}
            </Button>
          </Grid>
        )}
        {quotation.status !== 2 && quotation.status !== 3 && (
          <Grid item xs={6} sm={'auto'}>
            <Button size="small" data-cy={'cancelQuotation'} variant={'contained'} color={'error'} fullWidth onClick={cancel}>
              {t('cancel_quotation')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider />
      <QuotationSolutionsList quotation={quotation} />
      <QuotationEditDialog dialogRef={dialogRef} quotation={quotation} options={options} />
    </InfoCard>
  )
}
