import React, { RefObject } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useFetcher } from 'app/providers/fetcher.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { TermOfSalesItem, UploadTermOfSalesInput, uploadTermOfSalesInput } from 'api/models'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import { UploadZone } from 'app/components/form/upload-zone.component'

interface IDialogEditAcquisitionCost {
  type: string
  dialogRef: RefObject<DialogRef>
  termOfSales: TermOfSalesItem | null
}

export const DialogEditTermOfSales = function ({
  type,
  dialogRef,
  termOfSales,
}: IDialogEditAcquisitionCost) {
  const { t } = useTranslation()
  const { uploadTermOfSales, updateInternalDocument } = useFetcher()
  const { handleMutation, confirm } = useFeedback()
  const navigate = useNavigate()
  const method = useForm<UploadTermOfSalesInput>({
    mode: 'onChange',
    resolver: zodResolver(uploadTermOfSalesInput),
  })
  const { isValid, isSubmitting } = method.formState

  const handleConfirm = async (data: UploadTermOfSalesInput) => {
    if (termOfSales) {
      await handleMutation({
        mutation: updateInternalDocument,
        data: {
          type,
          id: termOfSales.id,
          data: {
            end: data.begin.clone().endOf('day').subtract(1, 'day'),
          },
        },
      })
    }

    await handleMutation({
      mutation: uploadTermOfSales,
      data: { type, data },
      onSuccess: () => {
        dialogRef.current?.close()
        navigate(0)
      },
    })
  }

  const handleSubmit = async (data: UploadTermOfSalesInput) => {
    confirm({
      content: t('confirm_add_terms_of_sales'),
      onConfirm: async () => {
        await handleConfirm(data)
      },
    })
  }

  const handleFilesChange = (files: File[]) => {
    method.setValue('file', files[0], { shouldValidate: true })
  }

  return (
    <Dialog
      ref={dialogRef}
      title={t(type === 'term-of-sales' ? 'add_terms_of_sales' : 'add_rules_of_procedure')}
      actions={
        <>
          <Button variant={'outlined'} onClick={dialogRef.current?.close} sx={{ mb: 5 }}>
            {t('cancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!isValid || isSubmitting}
            onClick={method.handleSubmit(handleSubmit)}
            sx={{ mb: 5, mr: 5 }}>
            {t('update')}
          </Button>
        </>
      }>
      <Typography fontWeight={'bold'} sx={{ mb: 2 }}>
        {t('PDF')}
      </Typography>
      <UploadZone
        accept="application/pdf"
        enforcedTypes={['application/pdf']}
        formatsCaption="PDF"
        onFilesChange={handleFilesChange}
      />

      <Typography fontWeight={'bold'} sx={{ mb: 2, mt: 5 }}>
        {t('begin')}
      </Typography>
      <ControlledDatePicker
        control={method.control}
        required={true}
        name={'begin'}
        minDate={termOfSales && termOfSales.begin ? dayjs(termOfSales.begin) : undefined}
        data-cy="range-picker"
      />
    </Dialog>
  )
}
