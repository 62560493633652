import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {Box, Stack} from '@mui/system'
import { KeyboardBackspace, Edit } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { ServiceDetails } from 'modules/services/components/service-details.component'
import {useFetcherV2} from "app/providers/fetcher_v2.provider";
import {useQueryFetcher} from "app/hooks/use-query-fetcher";

export const ParkingServiceView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { Services } = useFetcherV2()
  const navigate = useNavigate()
  const {data : mainService, isFetching : listIsLoading } = useQueryFetcher({
    queryKey : ['parking-service', 'get', id],
    queryFn : () => {
      if(!id) return;
      return Services.getParking(Number(id))
    }
  })
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box onClick={() => navigate(-1)} style={{lineHeight: '1em'}}>
              <KeyboardBackspace fontSize={'small'}/>
            </Box>
            <Typography variant="h1">{t('parking')}</Typography>
            <Link to={`/services/parking/${id}/edit`}>
              <Edit fontSize={'small'} />
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <ServiceDetails
        isLoading={listIsLoading || !mainService}
        mainService={mainService}
        type={'parking'}
      />
    </Container>
  )
}
