import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useList } from 'app/providers/list.provider'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { TextSnippet } from '@mui/icons-material'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Credit, CreditSum } from 'api/models'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatCurrency } from 'app/utils/format'
import { useApp } from 'app/providers/app.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { RangePicker } from 'app/components/filters/range-picker'

export const CreditsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['credit_status', 'status'],
      ['credit_type', 'types']
    ])
  )
  const { getCredits, getCreditSum, getCreditExport, searchParams, setSearchParams } = useFetcher()
  const [credits, setCredits] = useState<Credit[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summary, setSummary] = useState<CreditSum>({} as CreditSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(async (reset: boolean) => {
    setListIsLoading(true)
    getCredits
      .mutateAsync()
      .then((data) => {
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setCredits(reset ? data.items : (prev) => [...prev, ...data.items])
      })
      .finally(() => setListIsLoading(false))

    getCreditSum
      .mutateAsync()
      .then((sum) => setSummary(sum))
      .finally(() => setSummaryIsLoading(false))
  }, [])

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('amount', formatCurrency(summary.amount ?? 0))
    newSummaryItems.set('total_vat', formatCurrency(summary.amountVat ?? 0))
    newSummaryItems.set('amount_ttc', formatCurrency(summary.totalCost ?? 0))
    newSummaryItems.set('due_amount', formatCurrency(summary.dueAmount ?? 0))

    setSummaryItems(newSummaryItems)
  }, [summary])

  const exportToPDF = useCallback(async () => {
    await getCreditExport.mutateAsync()
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('credits')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <SearchCenterInput
            defaultIsCluster={false}
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
          <SearchClientInput
            slug="client"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="created_at"
            label={'created_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            dataCy={'range-picker'}
          />
          <Button variant="contained" startIcon={<TextSnippet />} onClick={exportToPDF}>
            {t('export')}
          </Button>
        </FiltersContainer>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {credits.length} / {total}
          </Typography>
        </Grid>
        <List
          items={credits}
          columns={[
            { label: t('reference'), slug: 'reference', link: { base: '/credits', slug: 'id' } },
            { label: t('type'), slug: 'type' },
            { label: t('client'), slug: 'clientName' },
            { label: t('amount'), slug: 'amount' },
            { label: t('vat'), slug: 'vat' },
            { label: t('amount_ttc'), slug: 'total' },
            { label: t('due_amount'), slug: 'due' },
            { label: t('created_at'), slug: 'createdAt' },
            { label: t('spent_at'), slug: 'spentAt' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {credits.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
