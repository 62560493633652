import { FormControl, Grid, MenuItem, TextField } from '@mui/material'
import React from 'react'
import { InvoiceInformation } from 'api/models'

interface IProps {
  t: any
  data: InvoiceInformation
}

export const ExistingPaymentComponent = ({ t, data }: IProps) => {
  return (
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
        <TextField
          select
          required
          label={t('Paiement')}
          size={'small'}
          fullWidth
          name={'existing_payment'}
          defaultValue={data.unlinkPayments[0]?.id}
        >
          {data.unlinkPayments.map((item: any) => (
            <MenuItem
              key={item.id}
              value={item.id}
            >{`${item.mode} ${item.period} : ${item.amount} €`}</MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Grid>
  )
}
