import React, { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, TextField, Grid, FormControlLabel } from '@mui/material'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { Notification, NotificationUpdate } from 'api/models/notifications'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import Autocomplete from '@mui/material/Autocomplete'
import { StyledChip } from 'app/components/chips/multi-select-chip'
import { FormItems } from 'api/models'
import { z } from 'zod'
import { MultiSelect } from 'app/components/form/multiselect'
import { EMAIL_REGEX } from 'app/constants/regex'

interface NotificationEditDialogProps {
  dialogRef: React.RefObject<DialogRef>
  formData: NotificationUpdate | null
  setFormData: (data: NotificationUpdate | null) => void
  refreshList: () => void
  currentId: number | null
  readOnlyData?: Notification
}

export function NotificationEditDialog({
  dialogRef,
  formData,
  setFormData,
  refreshList,
  currentId,
  readOnlyData,
}: NotificationEditDialogProps) {
  const { t } = useTranslation()
  const { updateNotification, getFormItems } = useFetcher()
  const { handleMutation } = useFeedback()
  const [formItems, setFormItems] = useState({} as FormItems)
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['staff_roles', 'roles'],
      ['staffs_notification', 'staffs'],
    ])
  )

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setFormItems(optionsData as FormItems)
    })
  }, [])

  const handleConfirmUpdate = useCallback(async () => {
    if (!formData || !currentId) return
    await handleMutation({
      mutation: updateNotification,
      data: {
        id: currentId,
        data: {
          notificationStaff: formData.notificationStaff,
          notificationRole: formData.notificationRole,
          additionalRecipients: formData.additionalRecipients,
          isCenterRecipient: formData.isCenterRecipient,
        },
      },
      onSuccess: () => {
        refreshList()
        dialogRef.current?.close()
      },
    })
  }, [formData, handleMutation, refreshList])

  const handleAdditionalRecipientsChange = useCallback(
    (value: string[]) => {
      if (!value.length) {
        setFormData({ ...formData, additionalRecipients: { to: [] } })
        setIsInvalidEmail(false)
        return
      }
      const isEmail = z
        .string()
        .regex(EMAIL_REGEX)
        .safeParse(value[value.length - 1])
      if (!isEmail.success) {
        setIsInvalidEmail(true)
        return
      }
      setIsInvalidEmail(false)
      setFormData({ ...formData, additionalRecipients: { to: value } })
    },
    [formData]
  )

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [])

  return (
    <Dialog
      ref={dialogRef}
      title={t('edit_notification')}
      onClose={() => {
        setFormData(null)
      }}
      actions={
        <>
          <Button variant="outlined" onClick={() => dialogRef.current?.close()}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handleConfirmUpdate}>
            {t('save')}
          </Button>
        </>
      }
    >
      <Grid container spacing={6} paddingTop={4}>
        <Grid item xs={12}>
          <TextField
            size={'small'}
            label={t('notification')}
            value={formData?.label ?? ''}
            disabled
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size={'small'}
            label={t('default_recipient')}
            value={readOnlyData?.autoRecipient ?? ''}
            disabled
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            label={t('staff')}
            name={t('staff')}
            valueSlug={'id'}
            labelSlug={'label'}
            items={
              formItems.staffs_notification?.values.map((item) => ({
                id: Number(item.id),
                label: item.label,
              })) ?? []
            }
            value={formData?.notificationStaff?.map((staff) => staff.id) ?? []}
            onChange={(value: number[]) => {
              setFormData({
                ...formData,
                notificationStaff: value.map((id) => ({ id })),
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            label={t('role')}
            name={t('role')}
            valueSlug={'id'}
            labelSlug={'label'}
            items={
              formItems.staff_roles?.values.map((item) => ({
                id: Number(item.id),
                label: item.label,
              })) ?? []
            }
            value={formData?.notificationRole?.map((staff) => staff.id) ?? []}
            onChange={(value: number[]) => {
              setFormData({
                ...formData,
                notificationRole: value.map((id) => ({ id })),
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            freeSolo
            value={formData?.additionalRecipients?.to ?? []}
            onChange={(e, value) => handleAdditionalRecipientsChange(value)}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index })
                return <StyledChip label={option} key={key} {...tagProps} />
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size={'small'}
                label={t('additional_recipients')}
                placeholder={t('email')}
                error={isInvalidEmail}
                helperText={isInvalidEmail ? t('invalid_email') : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {readOnlyData?.canNotifyCenter && (
            <FormControlLabel
              control={
                <Checkbox
                  size={'small'}
                  checked={formData?.isCenterRecipient ?? false}
                  onChange={(e) =>
                    setFormData({ ...formData, isCenterRecipient: e.target.checked })
                  }
                />
              }
              label={t('center_associated_in_copy')}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            size={'small'}
            label={t('description')}
            value={formData?.description ?? ''}
            disabled
            fullWidth
            multiline
            maxRows={5}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
