import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, Grid, Switch, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ConsumptionService, OptionService } from 'api/models'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import dayjs from 'dayjs'

interface IProps {
  methods: UseFormReturn<any>
  service: ConsumptionService | OptionService
  center: number | null
  serviceGeneral: ConsumptionService | OptionService
}

export default function AvailabilityOptAndConsComponent({
  methods,
  service,
  center,
  serviceGeneral
}: IProps) {
  const { t } = useTranslation()

  const isAvailable = useWatch({
    control: methods.control,
    name: 'isAvailable',
    defaultValue: service.isAvailable,
  })

  useEffect(() => {
    if (!isAvailable) {
      methods.setValue('isOnline', false)
    }
  }, [isAvailable, methods])

  useEffect(() => {
    methods.setValue('begin', service.begin !== null ? dayjs(service.begin) : null)
    methods.setValue('end', service.end !== null ? dayjs(service.end) : null)
    methods.setValue('isOnline', service.isOnline ?? true)
  }, [service])

  const handleOnlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue('isOnline', event.target.checked)
  }

  const handleAvailableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    methods.setValue('isAvailable', newValue)
    if (!newValue) {
      methods.setValue('isOnline', false)
    }
  }

  return (
    <>
      <TitleComponent
        text={t('availability')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={1}
      />
      <Divider sx={{ marginBottom: 6 }} />
      <Grid container spacing={5} marginBottom={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <ControlledDatePicker
              name={'begin'}
              control={methods.control}
              label={t('begin')}
              dataCy={'range-picker-begin'}
              slotProps={{
                textField: { required: true, placeholder : serviceGeneral.begin ? dayjs(serviceGeneral.begin).format('DD/MM/YYYY') : ''},
              }}
              required={true}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <ControlledDatePicker
              name={'end'}
              control={methods.control}
              label={t('end')}
              dataCy={'range-picker-end'}
              slotProps={{
                textField: { placeholder : serviceGeneral.end ? dayjs(serviceGeneral.end).format('DD/MM/YYYY') : ''}
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>
              {center ? t('available_client_space_per_center') : t('available')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Switch
              checked={isAvailable}
              onChange={handleAvailableChange}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>
              {center ? t('visible_from_client_space_for_this_center') : t('visible_client_space')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Switch
              checked={useWatch({
                control: methods.control,
                name: 'isOnline',
                defaultValue: service.isOnline ?? true,
              })}
              onChange={handleOnlineChange}
              disabled={!isAvailable}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
