import { Grid, Typography } from '@mui/material'
import { List, ListColumnsProps } from 'app/components/lists/list'
import { formatCurrency, formatDate } from 'app/utils/format'
import { ListPagination } from 'app/components/lists/list-pagination'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'

export const ParkingsListComponent = function ({
  parkingServices,
  refreshList,
  listIsLoading,
  centerId,
}: any) {
  const { t } = useTranslation()
  const { handleFilter, handleSort, isLast, total, orderBy } = useList()

  const columns = useMemo(() => {
    const _cols = [
      {
        label: t('label'),
        slug: 'label',
        link: {
          base: centerId ? `/centers/${centerId}/services/parking` : '/services/parking',
          slug: 'id',
        },
      },
      { label: t('typology'), slug: 'typology' },
      { label: t('floor'), slug: 'floor' },
      { label: t('capacity'), slug: 'capacity' },
      { label: t('building'), slug: 'building' },
      { label: t('monthly_price'), slug: 'price', valueFormatter: formatCurrency },
      { label: t('begin'), slug: 'begin', valueFormatter: formatDate },
      { label: t('end'), slug: 'end', valueFormatter: formatDate },
    ] as ListColumnsProps<any>

    if (!centerId) {
      _cols.splice(1, 0, { label: t('center'), slug: 'center' })
    }
    return _cols
  }, [centerId])

  return (
    <>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom>
          {parkingServices.length} / {total}
        </Typography>
      </Grid>
      <List
        items={parkingServices}
        columns={columns}
        handleReset={() => handleFilter(refreshList, true)}
        sort={orderBy}
        handleSort={(property) => handleSort(refreshList, property)}
        isLoading={listIsLoading}
        sx={{
          th: {
            textWrapMode: 'nowrap',
          },
        }}
      />
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom marginTop={2}>
          {parkingServices.length} / {total}
        </Typography>
      </Grid>
      {!isLast && !listIsLoading && (
        <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
      )}
    </>
  )
}
