import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, Grid, Switch, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MainService } from 'api/models'
import { UseFormReturn } from 'react-hook-form'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'

interface IProps {
  methods: UseFormReturn<any>
  mainService: MainService
}

export default function AvailabilityComponent({ methods, mainService }: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('availability')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={1}
      />
      <Divider sx={{ marginBottom: 6 }} />
      <Grid container spacing={5} marginBottom={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <ControlledDatePicker
              dataCy={'range-picker-begin'}
              name={'begin'}
              control={methods.control}
              label={t('begin')}
              required={true}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={8}>
            <ControlledDatePicker
              dataCy={'range-picker-end'}
              name={'end'}
              control={methods.control}
              label={t('end')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('visible_client_space')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Switch defaultChecked={mainService.isOnline} {...methods.register('isOnline')} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
