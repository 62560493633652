import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { Edit, KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useApp } from 'app/providers/app.provider'
import { OptionAndConsumptionServiceDetails } from 'modules/options/components/option-details.component'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

export const ConsumptionServiceView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id, centerId } = useParams()
  const { getRight } = useApp()
  const { Services } = useFetcherV2()
  const { data: consumption, isFetching: listIsLoading } =
    useQueryFetcher({
      queryKey: ['consumptions', 'get', id, centerId],
      queryFn: () => {
        if (!id) return
        return Services.getConsumption(Number(id), centerId ? Number(centerId) : undefined)
      },
    })

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={centerId ? `/centers/${centerId}` : `/consumptions`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{ consumption?.centerName ? `${t('consumptions_form')} | ${consumption.centerName}` : t('consumptions_form') }</Typography>
            {getRight('catalog_consumption', 'isEdit') && (
              <Link to={`./edit`}>
                <Edit fontSize={'small'} />
              </Link>
            )}
          </Stack>
        </Grid>
      </Grid>
      <OptionAndConsumptionServiceDetails
        isLoading={listIsLoading || !consumption}
        service={consumption}
        center={consumption?.centerId ?? null}
      />
  </Container>
  )
}
