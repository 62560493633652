import { z } from 'zod'
import { EMAIL_REGEX } from 'app/constants/regex'

export const createEnterpriseFormSchema = z.object({
  name: z.string(),
  center: z.number({ coerce: true }),
  workforce: z.coerce.number().optional(),
  address: z.string().optional(),
  zipcode: z.string().optional(),
  city: z.string().optional(),
  languageEnterprise: z.string(),
  firstname: z.string().min(1),
  lastname: z.string().min(1),
  email: z.string().regex(EMAIL_REGEX),
  phone: z.string().optional(),
  mobile: z
    .string()
    .min(10, 'A valid number is required')
    .regex(/^\d+$/, 'A valid number is required'),
  languageIndividual: z.string(),
  welcomeEmail: z.enum(['on', 'off']),
})
export type CreateEnterpriseForm = z.infer<typeof createEnterpriseFormSchema>

export const createSsidFormSchema = z.object({
  name: z.string().min(1),
  center: z.string(),
})

export type CreateSsidForm = z.infer<typeof createSsidFormSchema>
