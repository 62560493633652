import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, Typography, IconButton } from '@mui/material'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { List, ListColumnsProps } from 'app/components/lists/list'
import { TermOfSalesItem } from 'api/models'
import { formatDate } from 'app/utils/format'
import { AddCircle } from '@mui/icons-material'
import { DialogEditTermOfSales } from 'modules/termsOfSales/components/dialog-edit-termOfSales'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { useApp } from 'app/providers/app.provider'

export const InternalDocumentsView = ({ type }: { type: string }): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    initSort,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    handleSort,
    handleFilter,
  } = useList()
  const { getInternalDocuments } = useFetcher()
  const dialogRef = useRef<DialogRef>(null)
  const [termsOfSales, setTermsOfSales] = useState<TermOfSalesItem[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [selectedTermOfSales, setSelectedTermOfSales] = useState<TermOfSalesItem | null>(null)
  const [isGlobalAdd, setIsGlobalAdd] = useState<boolean>(false)
  const { getRight } = useApp()

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      getInternalDocuments
        .mutateAsync(type)
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setTermsOfSales(reset ? data.items : (prev) => [...prev, ...data.items])
          setIsGlobalAdd(data.items.length === 0 || data.items.every((item) => item.end))
        })
        .finally(() => setListIsLoading(false))
    },
    [type, getInternalDocuments, setIsLast, setOffset, setTotal]
  )

  const openAdd = (termOfSales: TermOfSalesItem | null) => () => {
    setSelectedTermOfSales(termOfSales)
    dialogRef.current?.open()
  }

  useEffect(() => {
    initSort([
      {
        property: 'begin',
        order: 'desc',
      },
    ]).then(async () => await refreshList(true))
  }, [type])

  const columns = useMemo(() => {
    const _items = [
      {
        label: t('id'),
        slug: 'id',
        link: { base: '.', slug: 'id' },
      },
      {
        label: t('begin'),
        slug: 'begin',
        valueFormatter: formatDate,
      },
      {
        label: t('end'),
        slug: 'end',
        condition: (item: TermOfSalesItem) => {
          if (getRight('terms_of_sales', 'isAdd') && !item.end) {
            return (
              <IconButton
                color={'primary'}
                size={'small'}
                onClick={openAdd(item)}
                data-cy="add-item"
              >
                <AddCircle fontSize={'small'} />
              </IconButton>
            )
          }
          return formatDate(item.end)
        },
      },
    ] as ListColumnsProps<TermOfSalesItem>

    if (type === 'term-of-sales') {
      _items.push({
        label: t('indexing_type'),
        slug: 'indexationTypeLabel',
      })
    }
    return _items
  }, [type])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t(type === 'term-of-sales' ? 'terms_of_sales' : 'internal_regulations')}
        </Typography>
        {getRight('terms_of_sales', 'isAdd') && !listIsLoading && isGlobalAdd && (
          <IconButton
            title={t('add_terms_of_sales')}
            aria-label={t('add_terms_of_sales')}
            color="primary"
            style={{ marginLeft: 7 }}
            onClick={openAdd(null)}
            data-cy="add-item"
          >
            <AddCircle fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {termsOfSales.length} / {total}
          </Typography>
        </Grid>
        <List
          items={termsOfSales}
          columns={columns}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {termsOfSales.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
      <DialogEditTermOfSales dialogRef={dialogRef} termOfSales={selectedTermOfSales} type={type} />
    </Container>
  )
}
