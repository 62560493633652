import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material'
import { InformationsComponent } from 'modules/invoices/components/informations.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { FormItem } from 'api/models'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { Link } from 'app/components/link.component'
import base64ConverterHelper from 'app/helpers/base64_converter.helper'
import { ActionsComponent } from 'modules/invoices/components/actions.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { PDFViewer } from 'app/components/pdf/PDFViewer'
import FolderOffIcon from '@mui/icons-material/FolderOff'
import { useApp } from 'app/providers/app.provider'
import { PaymentMode } from 'modules/invoices/constants/payment_mode'

export const InvoiceView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const { useGetInvoice, useGetInvoicePdf, useGetFormItemsWithFilters } = useFetcher()
  const { id } = useParams()
  const [expanded, setExpanded] = useState<string | false>('panel1')

  const {
    data: invoicePdf,
    isLoading: isPdfLoading,
    refetch: refetchPdf,
  } = useGetInvoicePdf(Number(id))
  const {
    data: invoiceInformation,
    isLoading: isInformationLoading,
    refetch: refetchInformation,
  } = useGetInvoice(Number(id))
  const { data: formItems } = useGetFormItemsWithFilters({
    filters: ['languages_to_pdf', 'credit_payment_mode_dynamic'],
    references_filters: { credit_payment_mode_dynamic: { invoiceId: id } },
  })

  const refresh = () => {
    void refetchInformation()
    void refetchPdf()
  }

  const pdf = useMemo(() => {
    if (!invoicePdf?.document_pdf) return ''
    const blob = base64ConverterHelper().base64ToBlob(invoicePdf.document_pdf, 'application/pdf')
    return URL.createObjectURL(blob)
  }, [invoicePdf])

  const paymentModes = useMemo<FormItem | null>(() => {
    if (!formItems?.credit_payment_mode_dynamic) return null

    const hasPayment = getRight('payment', 'isAdd')
    const hasPaymentTpe = getRight('payment_tpe', 'isAdd')
    const hasTransfer = getRight('transfer', 'isAdd')

    let filteredPaymentModes = [...formItems.credit_payment_mode_dynamic.values]
    if (!hasPayment) filteredPaymentModes = []
    if (!hasTransfer)
      filteredPaymentModes = filteredPaymentModes.filter((item) => item.id !== PaymentMode.TRANSFER)
    if (!hasPaymentTpe)
      filteredPaymentModes = filteredPaymentModes.filter((item) => item.id !== PaymentMode.TPE)

    return {
      ...formItems.credit_payment_mode_dynamic,
      values: filteredPaymentModes,
    }
  }, [formItems, getRight])

  return (
    <Container>
      <Box marginBottom="2rem">
        {isInformationLoading && <RectangularSkeleton />}
        {!isInformationLoading && invoiceInformation && (
          <Typography variant="h2" gutterBottom display="inline">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Link to={`/invoices`}>
                <KeyboardBackspace style={{ marginRight: 5 }} />
              </Link>{' '}
              <Typography variant="h2" style={{ marginRight: 5 }}>
                {t('invoice')}
              </Typography>
              <Typography variant="h3">{invoiceInformation.invoice.reference}</Typography>
            </Box>
          </Typography>
        )}
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={12} md={5}>
          <InformationsComponent
            refresh={refresh}
            data={invoiceInformation}
            isLoading={isInformationLoading}
            dataFilters={formItems?.languages_to_pdf}
          />
          {isInformationLoading && <CardSkeleton />}
          {!isInformationLoading && invoiceInformation && (
            <ActionsComponent
              refresh={refresh}
              translation={t}
              data={invoiceInformation}
              expanded={expanded}
              setExpanded={setExpanded}
              activityTypes={invoiceInformation.activities}
              paymentMode={paymentModes}
            />
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          {isPdfLoading && <CardSkeleton />}
          {!isPdfLoading && pdf && <PDFViewer pdf={pdf} />}
          {!isPdfLoading && !pdf && (
            <Grid container style={{ height: '100%' }} justifyContent="center">
              <Box display={'flex'} alignItems="center">
                <FolderOffIcon style={{ fontSize: 40, marginRight: 20 }} />
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                  {t('no_pdf')}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
