import { UseMutationResult } from '@tanstack/react-query'
import React, { createContext, ReactNode, useContext, useRef, useState } from 'react'
import { CheckExcelServiceResult, FormItemValue } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'

interface IUploadServicesContext {
  file: File | null
  fileInputRef: React.RefObject<HTMLInputElement>
  handleFileChange: (files: File[]) => void
  clearFile: () => void
  checkFileMutation: UseMutationResult<CheckExcelServiceResult, unknown, FormData>
  handleSubmitCheck: () => void
  centerSelected: FormItemValue | null
  setCenterSelected: (center: FormItemValue | null) => void
}

const UploadServicesContext = createContext<IUploadServicesContext>({
  file: null,
  fileInputRef: { current: null },
  handleFileChange: () => {},
  clearFile: () => {},
  checkFileMutation: {} as UseMutationResult<CheckExcelServiceResult, unknown, FormData>,
  handleSubmitCheck: () => {},
  centerSelected: null,
  setCenterSelected: () => {},
})

export const useUploadServicesContext = () => useContext(UploadServicesContext)

export function UploadServicesProvider({ children }: { children: ReactNode }) {
  const { handleMutation } = useFeedback()
  const { useCheckManyServicesExcel } = useFetcher()
  const checkFileMutation = useCheckManyServicesExcel()

  const [file, setFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [centerSelected, setCenterSelected] = useState<FormItemValue | null>(null)

  const handleFileChange = (files: File[]) => {
    setFile(files[0] || null)
    if (files[0] == null) clearFile()
  }

  const clearFile = () => {
    setFile(null)
    if (fileInputRef.current) fileInputRef.current.value = ''
    checkFileMutation.reset()
  }

  const handleSubmitCheck = async () => {
    if (!file || !centerSelected) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('center_id', String(centerSelected.id))
    await handleMutation({
      mutation: checkFileMutation,
      data: formData,
    })
  }

  return (
    <UploadServicesContext.Provider
      value={{
        file,
        fileInputRef,
        handleFileChange,
        clearFile,
        checkFileMutation,
        handleSubmitCheck,
        centerSelected,
        setCenterSelected,
      }}
    >
      {children}
    </UploadServicesContext.Provider>
  )
}
