import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'

import { CenterPlan, CenterPlanTypeEnum } from 'api/models'
import { TitleComponent } from 'app/components/titles/title.component'
import { DialogUploadZone } from 'app/components/dialog/dialog-upload-zone.component'
import { Add, Delete, Draw, Map, OpenInNew, Photo } from '@mui/icons-material'
import styled from '@emotion/styled'
import { useApp } from 'app/providers/app.provider'
import { DialogRef } from 'app/components/dialog/dialog.component'

export const ImagesCard = ({ images }: { images: Array<CenterPlan> | null }) => {
  const { t } = useTranslation()
  const { user } = useApp()

  return (
    <Card variant="outlined" sx={{ height: '100%', boxSizing: 'border-box' }} data-cy="images-card">
      <CardContent>
        <TitleComponent text={t('images')} variant={'h3'} />
      </CardContent>
      <Divider />
      {user?.rights.center.isEdit && (
        <>
          <CardContent>
            <AddImageForm />
          </CardContent>
          <Divider />
        </>
      )}
      <CardContent>
        <ImageList data-cy="image-list">
          {images?.map((image) => (
            <ImageContainer key={image.id}>
              <Image src={image.link} width={140} height={100} />
              <Typography fontSize={10} mt={2} fontWeight="bold" sx={{ wordBreak: 'break-all' }}>
                {image.name}
              </Typography>
              <ImageTypeTag
                color="primary"
                label={t(imageTypeMap[image.type].label)}
                icon={imageTypeMap[image.type].icon}
                size="small"
              />
              <ActionsContainer data-actions>
                <a href={image.link} target="_blank" rel="noreferrer">
                  <ActionButton variant="contained" size="small">
                    <OpenInNew fontSize="small" />
                  </ActionButton>
                </a>
                {user?.rights.center.isEdit && (
                  <ActionButton variant="contained" color="error" size="small">
                    <Delete fontSize="small" />
                  </ActionButton>
                )}
              </ActionsContainer>
            </ImageContainer>
          ))}
        </ImageList>

        {(images ?? []).length === 0 && <Typography>{t('no_image')}</Typography>}
      </CardContent>
    </Card>
  )
}

const AddImageForm = () => {
  const { t } = useTranslation()
  const uploadDialogRef = useRef<DialogRef>(null)

  return (
    <FormContainer>
      <Button variant="outlined" onClick={() => uploadDialogRef.current?.open()}>
        {t('load_image')}
      </Button>
      <FormControl fullWidth size="small">
        <InputLabel id="image-type-select">{t('image_type')}</InputLabel>
        <Select size="small" labelId="image-type-select" label={t('image_type')}>
          <MenuItem value={CenterPlanTypeEnum.CENTER_IMAGE}>{t('center')}</MenuItem>
          <MenuItem value={CenterPlanTypeEnum.PLAN_2D}>{t('floor_plan')}</MenuItem>
          <MenuItem value={CenterPlanTypeEnum.SIGNATURE}>{t('signature')}</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" fullWidth disabled startIcon={<Add />}>
        {t('add')}
      </Button>
      <DialogUploadZone
        title={t('TODO')}
        dialogRef={uploadDialogRef}
        onFilesChange={() => {}}
        accept={'text/csv, text/plain'}
        enforcedTypes={['text/csv', 'text/plain']}
      />
    </FormContainer>
  )
}

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: 'auto 130px auto',
  width: 'fit-content',

  '@media (max-width: 1100px)': {
    width: '100%',
    gridTemplateColumns: '1fr 130px',
    '& button': {
      gridColumn: '1 / -1',
    },
  },

  [theme.breakpoints.down('md')]: {
    width: 'fit-content',
    gridTemplateColumns: 'auto 130px auto',
    '& button': {
      gridColumn: 'unset',
    },
  },

  '@media (max-width: 500px)': {
    width: '100%',
    gridTemplateColumns: '1fr 130px',
    '& button': {
      gridColumn: '1 / -1',
    },
  },
}))

const imageTypeMap = {
  [CenterPlanTypeEnum.CENTER_IMAGE]: { icon: <Photo />, label: 'center' },
  [CenterPlanTypeEnum.PLAN_2D]: { icon: <Map />, label: 'floor_plan' },
  [CenterPlanTypeEnum.SIGNATURE]: { icon: <Draw />, label: 'signature' },
}

const ImageContainer = styled.div(({ theme }) => ({
  color: theme.palette.text.primary,
  transition: 'background-color 0.2s',
  borderRadius: 12,
  position: 'relative',
  padding: 4,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover [data-actions]': {
    opacity: '1 !important',
  },
}))

const ImageTypeTag = styled(Chip)({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 2,
  margin: 8,
  fontSize: 10,
  borderRadius: 4,
  height: 20,
  '& .MuiChip-label': {
    paddingRight: 4,
  },
  '& svg': {
    width: 14,
    height: 14,
    marginLeft: '2px !important',
  },
})

const ImageList = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: 1,
  gridTemplateColumns: 'repeat(auto-fit,minmax(140px, 1fr))',

  [theme.breakpoints.up('md')]: {
    maxHeight: 320,
    overflowY: 'auto',
  },
}))

const Image = styled.img(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  width: '100%',
  height: 100,
  verticalAlign: 'top',
  borderRadius: 8,
  objectFit: 'cover',
  display: 'inline-block',
}))

const ActionsContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  margin: '4px',
  height: 100,
  backgroundColor: 'rgba(0,0,0,0.4)',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  opacity: 0,
  transition: 'opacity 0.2s',
})

const ActionButton = styled(Button)({
  minWidth: 0,
  borderRadius: 99,
  width: 35,
  height: 35,
})
