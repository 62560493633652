import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Grid, Tab, Tabs, Typography } from '@mui/material'
import { TabPanel } from 'app/components/tab-panel.component'
import { useTranslation } from 'react-i18next'
import { Box, Stack } from '@mui/system'
import { CenterInformationsCard } from 'modules/centers/components/center-informations.component'
import { CenterConfigurationCard } from 'modules/centers/components/center-configuration.component'
import { CenterStaffsCard } from 'modules/centers/components/center-staffs.component'
import { CenterListAccesCard } from 'modules/centers/components/center-list-acces.component'
import { CenterGroupAccesCard } from 'modules/centers/components/center-group-acces.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { FormItems } from 'api/models'
import { CenterCatalog } from 'modules/centers/components/center-catalog.component'
import { CenterQuotationPreparation } from 'modules/centers/components/center-quotation-preparation.component'

const TAB_TYPE = {
  INFORMATIONS: 0,
  CONFIGURATION: 1,
  STAFF: 2,
  ACCESSGROUP: 3,
  ACCESSLIST: 4,
  CATALOG: 5,
  QUOTATION_PREPARATION: 6,
}

export const CenterView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(0)
  const { id } = useParams()
  const { useGetCenter } = useFetcher()
  const { data: center, isPending } = useGetCenter(id ? Number(id) : 0)
  const [formItems, setFormItems] = useState({} as FormItems)
  const { getFormItems } = useFetcher()
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([['companies', 'company']])
  )

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setFormItems(optionsData as FormItems)
    })
  }, [])

  useEffect(() => {
    initOptions(commonOptions)
  }, [initOptions, commonOptions])

  const handleChangeTab = async (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('center_sheet')} | {center?.name}{' '}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="body2">{center?.reference}</Typography>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        aria-label="company_tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ marginTop: 1 }}
      >
        <Tab label={t('informations')} value={TAB_TYPE.INFORMATIONS} />
        <Tab
          label={t('configuration')}
          iconPosition={'end'}
          value={TAB_TYPE.CONFIGURATION}
          disabled={isPending}
        />
        <Tab
          label={t('staff_word')}
          iconPosition={'end'}
          value={TAB_TYPE.STAFF}
          disabled={isPending}
        />
        {/*<Tab*/}
        {/*  label={t('quotation_preparation')}*/}
        {/*  value={TAB_TYPE.QUOTATION_PREPARATION}*/}
        {/*  disabled={isPending}*/}
        {/*  data-cy="quotation-preparation-tab"*/}
        {/*/>*/}
        <Tab
          label={t('access_group')}
          iconPosition={'end'}
          value={TAB_TYPE.ACCESSGROUP}
          disabled={isPending}
        />
        <Tab
          label={t('access_list')}
          iconPosition={'end'}
          value={TAB_TYPE.ACCESSLIST}
          disabled={isPending}
        />
        <Tab label={t('site_catalog')} value={TAB_TYPE.CATALOG} disabled={isPending} />
      </Tabs>
      <Divider
        sx={(theme) => ({
          marginBottom: 8,
          marginTop: '-1px',
          marginInline: '-' + theme.spacing(8),
        })}
      />

      <TabPanel value={tab} index={TAB_TYPE.INFORMATIONS}>
        <CenterInformationsCard center={center} isLoading={isPending}></CenterInformationsCard>
      </TabPanel>
      {center && (
        <>
          <TabPanel value={tab} index={TAB_TYPE.CONFIGURATION}>
            <CenterConfigurationCard
              center={center}
              companies={formItems?.companies?.values}
              isLoading={isPending}
            ></CenterConfigurationCard>
          </TabPanel>
          <TabPanel value={tab} index={TAB_TYPE.STAFF}>
            <CenterStaffsCard center={center}></CenterStaffsCard>
          </TabPanel>
          <TabPanel value={tab} index={TAB_TYPE.QUOTATION_PREPARATION}>
            <CenterQuotationPreparation center={center} />
          </TabPanel>
          <TabPanel value={tab} index={TAB_TYPE.ACCESSGROUP}>
            <CenterGroupAccesCard center={center}></CenterGroupAccesCard>
          </TabPanel>
          <TabPanel value={tab} index={TAB_TYPE.ACCESSLIST}>
            <CenterListAccesCard center={center}></CenterListAccesCard>
          </TabPanel>
          <TabPanel value={tab} index={TAB_TYPE.CATALOG}>
            <CenterCatalog center={center}></CenterCatalog>
          </TabPanel>
        </>
      )}
    </Box>
  )
}
