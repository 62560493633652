import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { FormItems, MainService, MainServiceBody, mainServiceBodySchema } from 'api/models'
import { Container, Grid, Typography, Paper as MuiPaper, Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import LanguageComponent from 'modules/services/components/languages.component'
import MainInformationsComponent from 'modules/services/components/main-informations.component'
import PriceCalculatorComponent from 'modules/services/components/price-calculator.component'
import dayjs from 'dayjs'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import AvailabilityComponent from 'modules/services/components/availability.component'

export const MainServiceEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getMainService, updateMainService, getFormItems, computePrice } = useFetcher()
  const { id } = useParams()
  const [mainService, setMainService] = useState<MainService>({} as MainService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState({} as FormItems)
  const { handleMutation } = useFeedback()
  const [calculatedPrice, setCalculatedPrice] = useState<string>()
  const navigate = useNavigate()

  const methods = useForm<MainServiceBody>({
    mode: 'onChange',
    resolver: zodResolver(
      mainServiceBodySchema.refine((data) => {
        if (!data.savedLabels) return false
        const fr = data.savedLabels.find((sl) => sl.language === 1)
        const prices = [data.dailyPrice, data.halfDayPrice, data.hourlyPrice].filter((val) => val)
        return fr && fr.label && (prices.length == 0 || prices.length === 3)
      })
    ),
  })
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([['main_services_typologies', 'typology']])
  )

  const data = methods.watch(['typology', 'surface', 'price'])

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setOptions(optionsData as FormItems)
    })
  }, [])

  useEffect(() => {
    initOptions(commonOptions).then(() => fetch())
  }, [])

  useEffect(() => {
    methods.setValue('begin', dayjs.utc(mainService.begin))
    methods.setValue('end', mainService.end ? dayjs.utc(mainService.end) : null)
    methods.setValue('surface', mainService.surface ?? 0)
    methods.setValue('typology', mainService.typologyId)
    methods.setValue('price', mainService.price ?? 0)
  }, [mainService])

  const calculatePrice = useCallback(async () => {
    if (data[2] && data[2] > 0) {
      setCalculatedPrice(String(data[2]))
    } else {
      await handleMutation({
        mutation: computePrice,
        data: {
          center: String(mainService.centerId),
          serviceType: String(mainService.serviceTypeId),
          typology: data[0] ? String(data[0]) : null,
          surface: data[1] ? String(data[1]) : null,
        },
        onSuccess: (response) => {
          setCalculatedPrice(String(response.computedPrice))
        },
      })
    }
  }, [mainService, data[0], data[1], data[2]])

  useEffect(() => {
    if (Object.keys(mainService).length > 0) {
      calculatePrice().then()
    }
  }, [mainService, data[0], data[1], data[2]])

  const fetch = async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getMainService,
      data: Number(id),
      onSuccess: (response) => {
        setMainService(response)
      },
      onEnd: () => setListIsLoading(false),
    })
  }

  const handleSubmit = useCallback(
    async (data: MainServiceBody) => {
      await handleMutation({
        confirm: {
          content: t('confirm_edit_service'),
        },
        mutation: updateMainService,
        data: { id: Number(id), data: data },
        onSuccess: () => navigate(`/services/main/${id}`),
      })
    },
    [updateMainService, id]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/services/main/${id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">
              {t('main_services_edit')} | {mainService.centerName}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} autoComplete="off">
          <MuiPaper sx={{ marginTop: 4, padding: 5, marginBottom: 5 }}>
            {listIsLoading ? (
              <CardSkeleton />
            ) : (
              <LanguageComponent control={methods.control} service={mainService} />
            )}
          </MuiPaper>
          <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
            {listIsLoading ? (
              <CardSkeleton />
            ) : (
              <MainInformationsComponent
                methods={methods}
                mainService={mainService}
                options={options}
              />
            )}
          </MuiPaper>
          <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
            {listIsLoading ? (
              <CardSkeleton />
            ) : (
              <AvailabilityComponent methods={methods} mainService={mainService} />
            )}
          </MuiPaper>
          <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
            {listIsLoading ? (
              <CardSkeleton />
            ) : (
              <PriceCalculatorComponent
                methods={methods}
                mainService={mainService}
                calculatedPrice={calculatedPrice}
              />
            )}
          </MuiPaper>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
            {listIsLoading ? (
              <RectangularSkeleton />
            ) : (
              <>
                <Link to={`/services/main/${id}`}>
                  <Button type={'button'} variant="outlined" size="small">
                    {t('cancel')}
                  </Button>
                </Link>
                <Button type={'submit'} variant="contained" size="small">
                  {t('modify')}
                </Button>
              </>
            )}
          </Box>
        </form>
      </FormProvider>
    </Container>
  )
}
