import { z } from 'zod'
import dayjs from 'dayjs'
import { itemListSchema } from 'api/models/commons'

const opportunityItemSchema = z.object({
  begin: z.string(),
  canal: z.string(),
  capacity: z.string(),
  center: z.string(),
  client: z.string(),
  clientId: z.number(),
  createdAt: z.string(),
  event: z.string(),
  eventType: z.string(),
  eventTypeLabel: z.string(),
  id: z.number(),
  reference: z.string(),
  source: z.string(),
  staff: z.string(),
  staffLight: z.string(),
  status: z.number(),
  statusLabel: z.string(),
  step: z.string(),
  subcanal: z.string(),
  surface: z.string(),
  type: z.string()
})

export type OpportunityItem = z.infer<typeof opportunityItemSchema>

const opportunityListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(opportunityItemSchema)
})

export type OpportunityList = z.infer<typeof opportunityListSchema>

const opportunityDetailsSchema = z.object({
  id: z.number(),
  reference: z.string(),
  centerId: z.number({ coerce: true }),
  centerName: z.string(),
  city: z.string().optional(),
  typeReference: z.string(),
  typeLabel: z.string(),
  surface: z.number(),
  capacity: z.number().nullable(),
  begin: z.instanceof(dayjs as any).optional(),
  commitmentId: z.number(),
  commitmentLabel: z.string(),
  end: z.instanceof(dayjs as any).optional(),
  statusId: z.number(),
  statusLabel: z.string(),
  stepId: z.number(),
  stepLabel: z.string(),
  enterpriseId: z.number(),
  enterpriseName: z.string(),
  enterpriseMainCenter: z.string(),
  qualificationId: z.number(),
  qualificationLabel: z.string(),
  userId: z.number(),
  userName: z.string(),
  userPhone: z.string(),
  staffName: z.string(),
  staffId: z.number(),
  prescriberId: z.number().nullable(),
  prescriberName: z.string().nullable(),
  serviceNumber: z.number({ coerce: true }).nullable(),
  signatoryId: z.number().nullable(),
  signatoryName: z.string().nullable(),
  deciderId: z.number().nullable(),
  deciderName: z.string().nullable(),
  canalId: z.number().nullable(),
  canalLabel: z.string().nullable(),
  subCanalId: z.number({ coerce: true }).nullable(),
  subCanalLabel: z.string().nullable(),
  sourceId: z.number({ coerce: true }).nullable(),
  sourceLabel: z.string().nullable(),
  groundRefusal: z.number().nullable()
})

export type Opportunity = z.infer<typeof opportunityDetailsSchema>

const opportunityQuotationSolution = z.object({
  id: z.number(),
  surface: z.number(),
  price: z.number(),
  commitmentLabel: z.string(),
  services: z.array(z.object({ serviceLabel: z.number() }))
})

const opportunityQuotation = z.object({
  id: z.number(),
  dueDate: z.string(),
  solutions: z.array(opportunityQuotationSolution)
})

export type OpportunityQuotations = z.infer<typeof opportunityQuotation>[]

const opportunityContract = z.object({
  id: z.number(),
  statusId: z.number(),
  statusLabel: z.string(),
  reference: z.string(),
  centerName: z.string(),
  price: z.number()
})
export type OpportunityContracts = z.infer<typeof opportunityContract>[]

const opportunityUser = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  job: z.string()
})

const EventItemSchema = z.object({
  id: z.number(),
  centerId: z.number(),
  centerName: z.string(),
  comment: z.string(),
  endLabel: z.string(),
  enterpriseId: z.number(),
  enterpriseName: z.string(),
  groundRefusalId: z.number(),
  groundRefusalLabel: z.string(),
  beginLabel: z.string(),
  opportunityId: z.number(),
  ownerId: z.number(),
  ownerName: z.string(),
  staffId: z.number(),
  staffName: z.string(),
  statusId: z.number(),
  statusLabel: z.string(),
  typeId: z.number(),
  typeLabel: z.string()
})

export type OpportunityUsers = z.infer<typeof opportunityUser>[]

const opportunityEvents = itemListSchema(EventItemSchema)

export type OpportunityEvents = z.infer<typeof opportunityEvents>
export type OpportunityEvent = z.infer<typeof EventItemSchema>

const pipelineStep = z.object({
  id: z.number(),
  step: z.string(),
  totalSurface: z.number(),
  count: z.number()
})

export type PipelineStep = z.infer<typeof pipelineStep>

const actualPipeline = z.object({
  lead: pipelineStep,
  leadQualified: pipelineStep,
  quotation: pipelineStep,
  visit: pipelineStep,
  contract: pipelineStep
})

export type ActualPipeline = z.infer<typeof actualPipeline>
