import React, { RefObject } from 'react'

import { IUploadZoneProps, UploadZone } from 'app/components/form/upload-zone.component'
import { Dialog, DialogRef } from './dialog.component'

export type IDialogUploadZoneProp = {
  dialogRef: RefObject<DialogRef>
  title: string
  actions?: React.ReactNode
  onClose?: () => void
} & IUploadZoneProps

export const DialogUploadZone: React.FC<IDialogUploadZoneProp> = (props) => {
  const { dialogRef, title, actions = null, onClose, ...modalProps } = props

  const handleClose = () => {
    modalProps.onFilesChange?.([])
    onClose?.()
  }

  return (
    <Dialog ref={dialogRef} title={title} actions={actions} onClose={handleClose}>
      <UploadZone {...modalProps} />
    </Dialog>
  )
}
