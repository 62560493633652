import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'
import { EMAIL_REGEX } from 'app/constants/regex'

const transformJsonStringToObject = (data: string) => {
  return JSON.parse(data)
}

const notificationSchema = z.object({
  id: z.number(),
  label: z.string(),
  notificationStaff: z.string().transform((data) => {
    const parsedData = transformJsonStringToObject(data)
    return {
      id: parsedData.id,
      names: parsedData.names,
    }
  }),
  notificationRole: z.string().transform((data) => {
    const parsedData = transformJsonStringToObject(data)
    return {
      id: parsedData.id,
      label: parsedData.label,
    }
  }),
  autoRecipient: z.string().nullable(),
  additionalRecipients: z.string().transform((data) => {
    const parsedData = transformJsonStringToObject(data)
    return {
      to: parsedData.to,
    }
  }),
  isActive: z.number().transform((e) => e === 1),
  isInternal: z.number().transform((e) => e === 1),
  isEditable: z.number().transform((e) => e === 1),
  canNotifyCenter: z.number().transform((e) => e === 1),
  isCenterRecipient: z.number().transform((e) => e === 1),
  description: z.string().nullable(),
})
export type Notification = z.infer<typeof notificationSchema>

const notificationListSchema = itemListSchema(notificationSchema)
export type NotificationList = z.infer<typeof notificationListSchema>

const NotificationsSchema = z.array(notificationSchema)
export { NotificationsSchema }
export type Notifications = z.infer<typeof NotificationsSchema>

const notificationUpdateSchema = z.object({
  label: z.string().optional(),
  notificationStaff: z
    .array(
      z.object({
        id: z.number(),
        names: z.string().optional(),
      })
    )
    .optional(),
  notificationRole: z
    .array(
      z.object({
        id: z.number(),
        label: z.string().optional(),
      })
    )
    .optional(),
  additionalRecipients: z
    .object({
      to: z.array(z.string().regex(EMAIL_REGEX)),
    })
    .optional(),
  isActive: z.boolean().optional(),
  isCenterRecipient: z.boolean().optional(),
  description: z.string().nullable().optional(),
})

export type NotificationUpdate = z.infer<typeof notificationUpdateSchema>
