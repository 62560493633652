import React, { useCallback, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { t } from 'i18next'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { MonthPicker } from 'app/components/filters/month-picker'
import { List } from 'app/components/lists/list'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatDocumentRequiredStatus } from 'app/utils/format'
import dayjs from 'dayjs'
import { LoadingButton } from '@mui/lab'
import { TextSnippet } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useFeedback } from 'app/providers/feedback.provider'

export const CompaniesDomiciledView = (): React.JSX.Element => {
  const { orderBy, handleSortByArray, handleFilterByArray, total, isLast } = useList();
  const { getHeadquartersExport, searchParams, setSearchParams } = useFetcher();
  const { Enterprises } = useFetcherV2();
  let beginDate = searchParams.get('begin');
  const { user } = useApp();
  if (!searchParams.get('center') && user?.mainCenter) {
    searchParams.set('center', String(user.mainCenter))
  }
  const { handleMutation } = useFeedback()
  useEffect(() => {
    if (!searchParams.has('begin')) {
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), 0, 1);
      beginDate = new Date(firstDay.getTime() - firstDay.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];
      searchParams.set('begin', beginDate);
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const {
    items: enterprisesDomiciled = [],
    refreshList,
    isLoading
  } = useQueryFetcherList({
    queryKey: ['enterprisesDomiciled', 'list'],
    queryFn: () => Enterprises.listEnterprisesDomiciled(searchParams.toString()),
    enabled: searchParams.has('center')
  })

  const handleExport = useCallback(async () => {
    await handleMutation({
      mutation: getHeadquartersExport,
      toastSuccess: t('export_success'),
    })
  }, [getHeadquartersExport])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('companies_domiciled')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilterByArray(refreshList, true)}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilterByArray(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            withDefaultValue={true}
            isDisableClearable={true}
          />
          <MonthPicker
            slug={'begin'}
            label={t('begin_date')}
            onChange={() => handleFilterByArray(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            views={['year', 'month', 'day']}
            format={'YYYY-MM-DD'}
            clearable={false}
            disableFuture={true}
          />
          <MonthPicker
            slug={'end'}
            label={t('end_date')}
            onChange={() => handleFilterByArray(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            views={['year', 'month', 'day']}
            format={'YYYY-MM-DD'}
            clearable={true}
            disabled={searchParams.get('begin') === null}
            minDate={dayjs(beginDate)}
          />
          {user?.rights.client.isExportable && (
            <LoadingButton
              loading={getHeadquartersExport.isPending}
              variant="contained"
              startIcon={<TextSnippet />}
              onClick={handleExport}
            >
              {t('export')}
            </LoadingButton>
          )}
        </FiltersContainer>
      </Grid>
      {!isLoading && enterprisesDomiciled?.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4" gutterBottom>
            {t('enterprises_domiciled_list_is_empty')}
          </Typography>
        </Box>
      ) : (
      <>
        <List
          items={enterprisesDomiciled}
          columns={[
            {
              label: t('enterprise'),
              slug: 'name',
              link: { base: `/enterprises`, slug: 'enterpriseId' },
            },
            {
              label: t('rcs'),
              slug: 'rcs',
            },
            {
              label: t('headquarters_begin'),
              slug: 'begin',
            },
            {
              label: t('headquarters_end'),
              slug: 'end',
            },
            {
              label: t('company_head_office'),
              slug: 'address',
            },
            {
              label: t('legal_representative'),
              slug: 'individualName',
              link: { base: '/individuals', slug: 'individualId' }
            },
            {
              label: t('surface_area'),
              slug: 'surface',
            },
            {
              label: t('documents_required'),
              slug: 'nbDocumentsInLine',
              condition: formatDocumentRequiredStatus,
              opacity: 1
            }
          ]}
          handleReset={() => handleFilterByArray(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSortByArray(refreshList, property)}
          isLoading={isLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {enterprisesDomiciled.length} / {total}
          </Typography>
        </Grid>
        {!isLast && (
          <ListPagination handleRedirect={() => handleFilterByArray(refreshList, false)} />
        )}
      </>
      )}
    </Container>
  )
}
