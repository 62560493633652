import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { FormItems, MainService, MainServiceAddBody, mainServiceAddBodySchema } from 'api/models'
import { Container, Grid, Typography, Paper as MuiPaper, Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import LanguageComponent from 'modules/services/components/languages.component'
import MainInformationsComponent from 'modules/services/components/main-informations.component'
import PriceCalculatorComponent from 'modules/services/components/price-calculator.component'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import AvailabilityComponent from 'modules/services/components/availability.component'

export const MainServiceAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { createMainService, getFormItems, computePrice } = useFetcher()
  const [mainService] = useState<MainService>({} as MainService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState({} as FormItems)
  const { handleMutation } = useFeedback()
  const [calculatedPrice, setCalculatedPrice] = useState<string>()
  const navigate = useNavigate()
  const methods = useForm<MainServiceAddBody>({
    mode: 'onChange',
    resolver: zodResolver(
      mainServiceAddBodySchema.refine((data) => {
        if (!data.savedLabels) return false
        const fr = data.savedLabels.find((sl) => sl.language === 1)
        const prices = [data.dailyPrice, data.halfDayPrice, data.hourlyPrice].filter((val) => val)
        return fr && fr.label && (prices.length === 0 || prices.length === 3)
      })
    ),
  })
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['main_services_typologies', 'typology'],
      ['centers', 'center'],
      ['main_services_types', 'serviceType'],
    ])
  )

  const data = methods.watch(['center', 'serviceType', 'typology', 'surface', 'price'])

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    setListIsLoading(true)
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setOptions(optionsData as FormItems)
      methods.setValue('center', Number(optionsData?.centers?.values[0].id) ?? null)
      methods.setValue(
        'serviceType',
        Number(optionsData?.main_services_types?.values[3].id) ?? null
      )
    })
    setListIsLoading(false)
  }, [])

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [])

  const calculatePrice = useCallback(async () => {
    if (data[4] && data[4] > 0) {
      setCalculatedPrice(String(data[4]))
    } else {
      await handleMutation({
        mutation: computePrice,
        data: {
          center: String(data[0]),
          serviceType: String(data[1]),
          typology: data[2] ? String(data[2]) : null,
          surface: data[3] ? String(data[3]) : null,
        },
        onSuccess: (response) => {
          setCalculatedPrice(String(response.computedPrice))
        },
      })
    }
  }, [data[0], data[1], data[2], data[3], data[4]])

  useEffect(() => {
    if (data[0] && data[1]) {
      calculatePrice().then()
    }
  }, [data[0], data[1], data[2], data[3], data[4]])

  const handleSubmit = async (data: MainServiceAddBody) => {
    await handleMutation({
      confirm: {
        content: t('confirm_add_main_service'),
      },
      mutation: createMainService,
      data: data,
      onSuccess: (data) => {
        navigate(`/services/main/${data.id}`)
      },
    })
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/services/main`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('add_main_service')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <form onSubmit={methods.handleSubmit(handleSubmit)} autoComplete="off">
        <MuiPaper sx={{ marginTop: 4, padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <LanguageComponent control={methods.control} service={mainService} />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <MainInformationsComponent
              methods={methods}
              mainService={mainService}
              options={options}
            />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <AvailabilityComponent methods={methods} mainService={mainService} />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <PriceCalculatorComponent
              methods={methods}
              mainService={mainService}
              calculatedPrice={calculatedPrice}
            />
          )}
        </MuiPaper>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
          {listIsLoading ? (
            <RectangularSkeleton />
          ) : (
            <>
              <Link to={`/services/main`}>
                <Button type={'button'} variant="outlined" size="small">
                  {t('cancel')}
                </Button>
              </Link>
              <Button
                type={'submit'}
                variant="contained"
                size="small"
                disabled={!methods.formState.isValid}
              >
                {t('add')}
              </Button>
            </>
          )}
        </Box>
      </form>
    </Container>
  )
}
