import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { OptionServiceBody, optionServiceBodySchema } from 'api/models'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { OptionAndConsumptionFormComponent } from 'modules/services/components/opt_and_cons_form.component'
import { ServiceEnum } from 'modules/services/enums/service_enum'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'

export const OptionEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id, centerId } = useParams()
  const navigate = useNavigate()
  const { Services, FormItems } = useFetcherV2()

  const methods = useForm<OptionServiceBody>({
    mode: 'onChange',
    defaultValues: {
      end: null,
    },
    resolver: zodResolver(
      optionServiceBodySchema.refine((data) => {
        return data.savedLabels.find((sl) => sl.language === 1 && sl.label.trim() !== '')
      })
    ),
  })
  const { isValid } = methods.formState

  const { data: optionService, isLoading: isLoadingOptions } = useQueryFetcher({
    queryKey: ['options', 'get', id, centerId],
    queryFn: () => {
      if (!id) return
      return Services.optionGet(Number(id), centerId ? Number(centerId) : undefined)
    },
  })

  const { data: optionServiceGeneral, isLoading: isLoadingOptionsGeneral } = useQueryFetcher({
    queryKey: ['options', 'get', id],
    queryFn: () => {
      if (!id) return
      return Services.optionGet(Number(id))
    },
  })
  const { data: formItems, isLoading: isLoadingItems } = useQueryFetcher({
    queryKey: ['form-items', 'get', id, centerId],
    queryFn: async () => {
      if (!id) return
      return (await FormItems.list(['options_services_types'])).options_services_types
    },
  })

  const isLoading = isLoadingItems || isLoadingOptions || isLoadingOptionsGeneral;

  const updateOptionService = useMutationFetcher({
    confirm: {
      content: t('confirm_edit_service')
    },
    mutationKey: ['option', 'update', id, centerId],
    mutationFn: ({ id, data }: { id: number; data: OptionServiceBody }) => {
      return Services.updateOption(id, data, centerId ? Number(centerId) : undefined)
    },
    toastSuccess: t('update_options_success'),
    onSettled: () => navigate(-1)
  })

  const handleSubmit = useCallback(
    async (data: OptionServiceBody) => {
      if (!optionService) return
      updateOptionService({
        id: Number(id),
        data: {
          ...data,
          isAvailable: data.isAvailable ?? optionServiceGeneral?.isAvailable,
        },
      })
    },
    [updateOptionService]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={centerId ? `/centers/${centerId}` : `/options/${id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{ optionService?.centerName ? `${t('option')} | ${optionService.centerName}` : t('option') }</Typography>
          </Stack>
        </Grid>
      </Grid>
      <OptionAndConsumptionFormComponent
        methods={methods}
        handleSubmit={handleSubmit}
        listIsLoading={isLoading}
        formItems={formItems}
        service={optionService}
        serviceType={ServiceEnum.OPTION}
        disabled={!!centerId}
        serviceGeneral={optionServiceGeneral}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
        {isLoading ? (
          <RectangularSkeleton />
        ) : (
          <>
            <Link to={centerId ? `/centers/${centerId}` : `/options/${id}`}>
              <Button type={'button'} variant="outlined" size="small">
                {t('cancel')}
              </Button>
            </Link>
            <Button
              type={'submit'}
              variant="contained"
              size="small"
              disabled={!isValid}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </>
        )}
      </Box>
    </Container>
  )
}
