import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { AddCircle } from '@mui/icons-material'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'
import { ConsumptionsListComponent } from 'modules/services/components/consumptions-list.component'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'

export const ConsumptionsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { filtersList, initFilters, handleFilterByArray } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['consumption_type', 'type'],
      ['consumption_status', 'status'],
    ])
  )
  const { getRight } = useApp()
  const navigate = useNavigate()
  const { Consumptions } = useFetcherV2()

  const {
    items: consumptions,
    isFetching: listIsLoading,
    refreshList,
  } = useQueryFetcherList({
    queryKey: ['consumption', 'list', searchParams],
    queryFn: () => {
      return Consumptions.list(searchParams.toString())
    },
  })

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('consumptions')}
          {getRight('catalog_consumption', 'isAdd') && (
            <IconButton
              title={t('add_options')}
              aria-label={t('add_options')}
              color="primary"
              onClick={() => navigate('/services/consumptions/add')}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilterByArray(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersBox>
      </Grid>
      <ConsumptionsListComponent
        listIsLoading={listIsLoading}
        refreshList={refreshList}
        consumptions={consumptions}
      />
    </Container>
  )
}
