import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import {
  FormItems,
  FormItemValue,
  ParkingService,
  ParkingServiceAddBody,
  parkingServiceAddBodySchema,
} from 'api/models'
import { Container, Grid, Typography, Paper as MuiPaper, Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import LanguageComponent from 'modules/services/components/languages.component'
import ParkingMainInformationsComponent from 'modules/services/components/parking-main-informations.component'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import AvailabilityComponent from 'modules/services/components/availability.component'
import ParkingPriceComponent from 'modules/services/components/parking_price.component'

export const ParkingServiceAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { createParkingService, getFilters, getFormItems } = useFetcher()
  const [parkingService] = useState<ParkingService>({} as ParkingService)
  const [typologies, setTypologies] = useState<FormItemValue[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState({} as FormItems)
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const methods = useForm<ParkingServiceAddBody>({
    mode: 'onChange',
    resolver: zodResolver(
      parkingServiceAddBodySchema.refine((data) => {
        if (!data.savedLabels) return false
        const fr = data.savedLabels.find((sl) => sl.language === 1)
        return fr && fr.label && data.begin && data.price && data.typology
      })
    ),
  })
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'center']])
  )

  methods.watch(['center', 'typology'])

  const getData = useCallback(async () => {
    setListIsLoading(true)
    await handleMutation({
      mutation: getFilters,
      data: { variables: ['parking_services_typologies'], params: {} },
      onSuccess: (data) => {
        if (data && data.parking_services_typologies) {
          setTypologies(data.parking_services_typologies.values)
        }
      },
    })
    setListIsLoading(false)
  }, [getFilters, handleMutation])

  useEffect(() => {
    getData().then()
  }, [])

  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      setListIsLoading(true)
      await getFormItems
        .mutateAsync(Array.from(commonOptions.keys() as any))
        .then((optionsData) => {
          setOptions(optionsData as FormItems)
          methods.setValue('center', Number(optionsData?.centers?.default) ?? null)
        })
      setListIsLoading(false)
    },
    [methods]
  )

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [commonOptions, initOptions])

  const handleSubmit = async (data: ParkingServiceAddBody) => {
    await handleMutation({
      confirm: {
        content: t('confirm_add_parking_service'),
      },
      mutation: createParkingService,
      data: data,
      onSuccess: (data) => {
        navigate(`/services/parking/${data.id}`)
      },
      onError: (error) => {
        console.log(error)
      },
    })
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/services/parking`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('parking_add')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <form onSubmit={methods.handleSubmit(handleSubmit)} autoComplete="off">
        <MuiPaper sx={{ marginTop: 4, padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <LanguageComponent control={methods.control} service={parkingService} />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <ParkingMainInformationsComponent
              methods={methods}
              options={options}
              parkingService={parkingService}
              typologies={typologies}
            />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <AvailabilityComponent methods={methods} mainService={parkingService} />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <ParkingPriceComponent methods={methods} parkingService={parkingService} />
          )}
        </MuiPaper>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
          {listIsLoading ? (
            <RectangularSkeleton />
          ) : (
            <>
              <Link to={`/services/parking`}>
                <Button type={'button'} variant="outlined" size="small">
                  {t('cancel')}
                </Button>
              </Link>
              <Button
                type={'submit'}
                variant="contained"
                size="small"
                disabled={!methods.formState.isValid}
              >
                {t('add')}
              </Button>
            </>
          )}
        </Box>
      </form>
    </Container>
  )
}
