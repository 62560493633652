import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useFeedback } from 'app/providers/feedback.provider'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export const useQueryFetcher = function <A>(params: UseQueryOptions<A>) {
  const { toast } = useFeedback()
  const { t } = useTranslation()
  const props = useQuery({
    ...params,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (props.error) {
      toast({ variant: 'error', message: t('error') })
    }
  }, [props])

  return props
}
