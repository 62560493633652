import { Grid, Typography } from '@mui/material'
import { List, ListColumnsProps } from 'app/components/lists/list'
import { Collections, PeopleAlt, SquareFoot } from '@mui/icons-material'
import {
  formatCurrency,
  formatDate,
  formatServicePrice,
  formatSurface,
  isImageAssociated,
} from 'app/utils/format'
import { Main } from 'api/models'
import { ListPagination } from 'app/components/lists/list-pagination'
import {
  DialogRef,
  EditImagesDialog,
  typeTags,
} from 'app/components/dialog/edit-images-dialog.component'
import React, { useMemo, useRef, useState } from 'react'
import { useList } from 'app/providers/list.provider'
import { useTranslation } from 'react-i18next'

export const MainServicesListComponent = function ({
  mainServices,
  listIsLoading,
  refreshList,
  centerId,
}: {
  listIsLoading: boolean
  refreshList: (reset: boolean) => Promise<void>
  mainServices: Main[]
  centerId?: number
}) {
  const { t } = useTranslation()
  const { handleSort, handleFilter, total, isLast, orderBy } = useList()
  const [currentService, setCurrentService] = useState<Main | null>(null)
  const dialogRef = useRef<DialogRef>(null)
  const handleOpenDialog = (item: Main) => {
    setCurrentService(item)
    dialogRef.current?.open()
  }

  const columns = useMemo(() => {
    const _cols = [
      {
        label: t('label'),
        slug: 'label',
        unsorted: true,
        link: {
          base: centerId ? `/centers/${centerId}/services/main` : '/services/main',
          slug: 'id',
        },
      },
      { label: t('type'), slug: 'type', unsorted: true },
      { label: t('typology'), slug: 'typology', unsorted: true },
      { label: PeopleAlt, slug: 'capacity', unsorted: true },
      { label: SquareFoot, slug: 'surface', valueFormatter: formatSurface, unsorted: true },
      { label: t('price'), slug: 'price', condition: formatServicePrice, unsorted: true },
      {
        label: t('daily_price'),
        slug: 'dailyPrice',
        valueFormatter: formatCurrency,
        unsorted: true,
      },
      {
        label: t('half_day_price'),
        slug: 'halfdayPrice',
        valueFormatter: formatCurrency,
        unsorted: true,
      },
      {
        label: t('hourly_price'),
        slug: 'hourlyPrice',
        valueFormatter: formatCurrency,
        unsorted: true,
      },
      { label: t('begin'), slug: 'begin', valueFormatter: formatDate, unsorted: true },
      { label: t('end'), slug: 'end', valueFormatter: formatDate, unsorted: true },
      {
        label: Collections,
        slug: 'isImageAssociated',
        unsorted: true,
        condition: (item: Main) => isImageAssociated(item, handleOpenDialog),
      },
    ] as ListColumnsProps<Main>

    if (!centerId) {
      _cols.splice(1, 0, { label: t('center'), slug: 'center', unsorted: true })
    }

    return _cols
  }, [centerId])

  return (
    <>
      <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
        <Typography variant="body2" gutterBottom>
          {mainServices.length} / {total}
        </Typography>
      </Grid>
      <List
        items={mainServices}
        columns={columns}
        handleReset={() => handleFilter(refreshList, true)}
        sort={orderBy}
        handleSort={(property) => handleSort(() => refreshList(true), property)}
        isLoading={listIsLoading}
        sx={{
          th: {
            textWrapMode: 'nowrap',
          },
        }}
      />
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom marginTop={2}>
          {mainServices.length} / {total}
        </Typography>
      </Grid>
      {!isLast && !listIsLoading && (
        <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
      )}

      <EditImagesDialog
        type={typeTags.services}
        entityLabel={currentService?.label ?? ''}
        entityId={currentService ? Number(currentService.id) : 0}
        ref={dialogRef}
        onClose={() => {
          refreshList(true)
        }}
      />
    </>
  )
}
