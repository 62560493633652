import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConsumptionsServiceBody, consumptionsServiceBodySchema } from 'api/models'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { OptionAndConsumptionFormComponent } from 'modules/services/components/opt_and_cons_form.component'
import { ServiceEnum } from 'modules/services/enums/service_enum'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'

export const ConsumptionServiceEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id, centerId } = useParams()
  const navigate = useNavigate()
  const { Services, FormItems } = useFetcherV2()

  const methods = useForm<ConsumptionsServiceBody>({
    mode: 'onChange',
    defaultValues: {
      end: null,
    },
    resolver: zodResolver(
      consumptionsServiceBodySchema.refine((data) => {
        return data.savedLabels.find((sl) => sl.language === 1 && sl.label.trim() !== '')
      })
    ),
  })
  const { isValid } = methods.formState

  const { data: consumption, isLoading: isLoadingConsumptions } =
    useQueryFetcher({
      queryKey: ['consumptions', 'get', id, centerId],
      queryFn: () => {
        if (!id) return
        return Services.getConsumption(Number(id), centerId ? Number(centerId) : undefined)
      },
    })

  const { data: consumptionGeneral, isLoading: isLoadingConsumptionsGeneral } =
    useQueryFetcher({
      queryKey: ['consumptions', 'get', id],
      queryFn: () => {
        if (!id) return
        return Services.getConsumption(Number(id))
      },
    })

  const { data: formItems, isLoading: isLoadingItems } = useQueryFetcher({
    queryKey: ['form-items', 'get', id, centerId],
    queryFn: async () => {
      if (!id) return
      return (await FormItems.list(['options_services_types'])).options_services_types
    },
  })

  const isLoading = isLoadingItems || isLoadingConsumptions || isLoadingConsumptionsGeneral

  const updateConsumptionService = useMutationFetcher({
    confirm: {
      content: t('confirm_edit_service')
    },
    mutationKey: ['consumption', 'update', id, centerId],
    mutationFn: ({ id, data }: { id: number; data: ConsumptionsServiceBody }) => {
      return Services.updateConsumption(id, data, centerId ? Number(centerId) : undefined)
    },
    toastSuccess: t('update_options_success'),
    onSettled: () => navigate(-1)
  })

  const handleSubmit = useCallback(
    async (data: ConsumptionsServiceBody) => {
      if (!consumption) return
      updateConsumptionService({
        id: Number(id),
        data: {
          ...data,
          isAvailable: data.isAvailable ?? consumptionGeneral?.isAvailable,
        },
      })
    },
    [updateConsumptionService]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={centerId ? `/centers/${centerId}` : `/consumptions/${id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{ consumption?.centerName ? `${t('consumption_edit')} | ${consumption.centerName}` : t('consumption_edit') }</Typography>
          </Stack>
        </Grid>
      </Grid>
      <OptionAndConsumptionFormComponent
        methods={methods}
        handleSubmit={handleSubmit}
        listIsLoading={isLoading}
        formItems={formItems}
        service={consumption}
        serviceType={ServiceEnum.CONSUMPTION}
        disabled={!!centerId}
        serviceGeneral={consumptionGeneral}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
        {isLoading ? (
          <RectangularSkeleton />
        ) : (
          <>
            <Link to={centerId ? `/centers/${centerId}` : `/consumptions/${id}`}>
              <Button type={'button'} variant="outlined" size="small">
                {t('cancel')}
              </Button>
            </Link>
            <Button
              type={'submit'}
              variant="contained"
              size="small"
              disabled={!isValid}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </>
        )}
      </Box>
    </Container>
  )
}
