import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, Grid, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { ParkingService } from 'api/models'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { Euro, Lock } from '@mui/icons-material'

interface IProps {
  parkingService: ParkingService
  methods: UseFormReturn<any>
}

export default function ParkingPriceComponent({ methods, parkingService }: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('price')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={1}
      />
      <Divider sx={{ marginBottom: 6 }} />
      <Grid container item xs={12} sm={6}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            size={'small'}
            label={t('monthly_price_locked')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock fontSize={'small'} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Euro fontSize={'small'} />
                </InputAdornment>
              ),
              inputProps: {
                step: 0.01,
              },
            }}
            type="number"
            variant="outlined"
            defaultValue={parkingService.price}
            {...methods.register('price', {
              setValueAs: (value) => (value && value > 0 ? parseFloat(value) : undefined),
            })}
            data-cy={'monthly-price-service-parking'}
            required={true}
          />
        </Grid>
      </Grid>
    </>
  )
}
