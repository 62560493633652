import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { ContactsByContractList } from 'api/models/contacts'

export const contactsRouter = ({ token }: Ctx) => ({
  getContacts: async (params: string) =>
    fetcher<ContactsByContractList>({
      url: `${BASE_URL}${API.CONTACTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),

  getContactExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.CONTACTS_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'contacts.xlsx'
    )
})
